/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import style from "../../Styles/reports.module.css";
import { formatNumber } from "../../Utils/formatDate";

export default function GraphicBar(props: any) {
  function sumByYear(data: any) {
    const yearSums: any = {};

    data?.forEach((item: any) => {
      Object.keys(item).forEach((key: any) => {
        // Check if the key is a year (i.e., a number)
        if (!isNaN(key)) {
          // Add the value to the corresponding year in yearSums
          if (!yearSums[key]) {
            yearSums[key] = 0;
          }
          yearSums[key] += item[key];
        }
      });
    });

    return yearSums;
  }

  /**
   *
   * @param data
   * @returns calculated averaged value by year
   */
  function averageByYear(data: any) {
    const yearSums: any = {};
    const yearCounts: any = {};

    data.forEach((item: any) => {
      Object.keys(item).forEach((key: any) => {
        // Check if the key is a year (i.e., a number)
        if (!isNaN(key)) {
          // Add the value to the corresponding year in yearSums
          if (!yearSums[key]) {
            yearSums[key] = 0;
            yearCounts[key] = 0;
          }
          yearSums[key] += item[key];

          // Increment the month count for this year
          yearCounts[key] += 1;
        }
      });
    });

    const result: any = [];
    Object.keys(yearSums).forEach((year: any) => {
      const average = yearSums[year] / yearCounts[year];
      result[year] = average;
    });

    return result;
  }

  return (
    <div
      style={{
        width: "100%",
        height: props.miniGraph ? "400px" : "500px",
        marginTop: 20,
        textAlign: "center",
      }}
    >
      <p className={style.statisticallyText}>
        <span>{props.title}</span>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 2,
              fontSize: 13,
              lineHeight: 0.5,
              fontWeight: 600,
            }}
          >
            {Object.keys(sumByYear(props.data)).map((key: any) => (
              <p>{key + ":"}</p>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 2,
              fontSize: 13,
              lineHeight: 0.5,
            }}
          >
            {props.averageValue
              ? Object.keys(sumByYear(props.data)).map((key: any) => (
                  <>
                    <p>
                      {props.noCurency
                        ? formatNumber(
                            parseFloat(
                              averageByYear(props.data)[key].toFixed(0)
                            )
                          )
                        : formatNumber(
                            parseFloat(
                              averageByYear(props.data)[key].toFixed(2)
                            )
                          )}
                      {props.noCurency ? " " : " Lei"}
                    </p>
                  </>
                ))
              : Object.keys(sumByYear(props.data)).map((key: any) => (
                  <>
                    <p>
                      {props.noCurency
                        ? formatNumber(
                            parseFloat(sumByYear(props.data)[key].toFixed(0))
                          )
                        : formatNumber(
                            parseFloat(sumByYear(props.data)[key].toFixed(2))
                          )}
                      {props.noCurency ? " " : " Lei"}
                    </p>
                  </>
                ))}
          </div>
        </div>
      </p>

      <ResponsiveBar
        data={props.data}
        groupMode="grouped"
        margin={{ top: 10, right: 100, bottom: 200, left: 80 }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "gray",
              },
            },
            legend: {
              text: {
                fill: "gray",
              },
            },
            ticks: {
              line: {
                stroke: "gray",
                strokeWidth: 1,
              },
              text: {
                fill: "gray",
              },
            },
          },
          legends: {
            text: {
              fill: "gray",
            },
          },
        }}
        keys={Object.keys(sumByYear(props.data))}
        indexBy="month"
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: props.color ? "pastel1" : "pastel2" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: "Lunile anului",
          legendPosition: "middle",
          legendOffset: 100,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.noCurency ? props.title : `${props.title} (LEI)`,
          legendPosition: "middle",
          legendOffset: -60,
        }}
        enableLabel={props.enableLabel ? true : false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
      />
    </div>
  );
}
