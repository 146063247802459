/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useReducer } from "react";
import { tipStatus } from "../Utils/autocompleteEnum";
import { urlEnum } from "../Utils/urlEnum";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import moment from "moment";
import { VehicleModel } from "../Models/VehicleModel";
import { toast } from "react-toastify";
import { Vocabulary } from "../Utils/Vocabulary";
import { disableTostifyConfig } from "../Utils/utils";

export const VehicleContext = createContext({
  invoices: {
    OPInvoice: [],
    clientInvoice: [],
    providerInvoice: [],
    reservedDocuments: [],
  },
  vehicle: new VehicleModel(),
  documents: [],
  images: [],
});

const actions = {
  ON_CHANGE_VEHICLE: "ON_CHANGE_VEHICLE",
  ON_CHANGE_VEHICLE_PRICES: "ON_CHANGE_VEHICLE_PRICES",
  CHANGE_VEHICLE_ACQUISITION_DATE: "CHANGE_VEHICLE_ACQUISITION_DATE",
  CHANGE_VEHICLE_RESERVATION_DATE: "CHANGE_VEHICLE_RESERVATION_DATE",
  CHANGE_VEHICLE_TRANSPORT_DATE: "CHANGE_VEHICLE_TRANSPORT_DATE",
  CHANGE_VEHICLE_TRANSPORT_ESTIMATED_DATE:
    "CHANGE_VEHICLE_TRANSPORT_ESTIMATED_DATE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  UPDATE_VEHICLE_INVOICES: "UPDATE_VEHICLE_INVOICES",
  UPDATE_VEHICLE_DOCUMENTS: "UPDATE_VEHICLE_DOCUMENTS",
  UPDATE_VEHICLE_IMAGES: "UPDATE_VEHICLE_IMAGES",
  UPDATE_VEHICLE_INVOICE_TYPE: "UPDATE_VEHICLE_INVOICE_TYPE",
  RESET_STATE: "RESET_STATE",
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case actions.ON_CHANGE_VEHICLE_PRICES:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          [action.state.name]: parseFloat(action.state.value),
        },
      };
    case actions.ON_CHANGE_VEHICLE:
      return {
        ...state,
        vehicle: { ...state.vehicle, [action.state.name]: action.state.value },
      };
    case actions.CHANGE_VEHICLE_ACQUISITION_DATE:
      return {
        ...state,
        vehicle: { ...state.vehicle, data_achizitie: action.state?._d },
      };
    case actions.CHANGE_VEHICLE_RESERVATION_DATE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          data_rezervare: action?.state?._d ? action.state?._d : null,
        },
      };
    case actions.CHANGE_VEHICLE_TRANSPORT_DATE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          pickupDate: action?.state?._d ? action.state?._d : null,
        },
      };
    case actions.CHANGE_VEHICLE_TRANSPORT_ESTIMATED_DATE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          estimatedArrivalDate: action?.state?._d ? action.state?._d : null,
        },
      };
    case actions.UPDATE_VEHICLE:
      return {
        ...state,
        vehicle: action.state,
      };
    case actions.UPDATE_VEHICLE_INVOICES:
      return {
        ...state,
        invoices: action.state,
      };
    case actions.UPDATE_VEHICLE_DOCUMENTS:
      return {
        ...state,
        documents: action.state,
      };
    case actions.UPDATE_VEHICLE_IMAGES:
      return {
        ...state,
        images: action.state,
      };
    case actions.UPDATE_VEHICLE_INVOICE_TYPE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.state.name]: action.state.value,
        },
      };
    case actions.RESET_STATE:
      return { ...action.state };
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
}

export const VehicleContextProvider = (props: any) => {
  const initState: any = {
    invoices: {
      OPInvoice: new Array<any>(),
      clientInvoice: new Array<any>(),
      providerInvoice: new Array<any>(),
      reservedDocuments: new Array<any>(),
    },
    vehicle: new VehicleModel(),
    documents: [],
    images: [],
  };

  const [state, dispatch] = useReducer(reducer, initState);

  const value: any = {
    state: state,
    /**
     *
     * @param state
     */
    onChangeVehicle: (state: any) => {
      dispatch({ type: actions.ON_CHANGE_VEHICLE, state });
    },
    /**
     *
     * @param state
     */
    onChangeVehiclePrices: (state: any) => {
      dispatch({ type: actions.ON_CHANGE_VEHICLE_PRICES, state });
    },

    /**
     *
     * @param state
     */
    onChangeVehicleAcquisitionDate: (state: any) => {
      dispatch({ type: actions.CHANGE_VEHICLE_ACQUISITION_DATE, state });
    },
    /**
     *
     * @param state
     */
    onChangeVehicleReservationDate: (state: any) => {
      dispatch({ type: actions.CHANGE_VEHICLE_RESERVATION_DATE, state });
    },
    /**
     *
     * @param state
     */
    onChangeVehicleTransportDate: (state: any) => {
      dispatch({ type: actions.CHANGE_VEHICLE_TRANSPORT_DATE, state });
    },
    /**
     *
     * @param state
     */
    onChangeVehicleTransportEstimatedDate: (state: any) => {
      dispatch({
        type: actions.CHANGE_VEHICLE_TRANSPORT_ESTIMATED_DATE,
        state,
      });
    },

    /**
     *
     * @param state
     */
    updateVehicle: (state: any) => {
      dispatch({ type: actions.UPDATE_VEHICLE, state });
    },
    /**
     *
     * @param state
     */
    updateVehicleInvoices: (state: any) => {
      dispatch({ type: actions.UPDATE_VEHICLE_INVOICES, state });
    },
    /**
     *
     * @param state
     */
    updateVehicleDocuments: (state: any) => {
      dispatch({ type: actions.UPDATE_VEHICLE_DOCUMENTS, state });
    },
    /**
     *
     * @param state
     */
    updateVehicleImages: (state: any) => {
      dispatch({ type: actions.UPDATE_VEHICLE_IMAGES, state });
    },
    /**
     *
     * @param state
     */
    updateVehicleInvoiceType: (state: any) => {
      dispatch({ type: actions.UPDATE_VEHICLE_INVOICE_TYPE, state });
    },
    /**
     *
     * @param state
     */
    resetState: (state: any) => {
      dispatch({ type: actions.RESET_STATE, state });
    },
    saveWithFile: saveVehicleWithFile,
    saveWithoutFile: saveWithoutFile,
    updateIndex: updateIndex,
    updateModifyBy: updateModifyBy,
  };

  /**
   *  saves a vehicle with a file
   * @param fileType
   * @param documentType
   * @param validate
   */
  async function saveWithoutFile(
    validate: () => boolean,
    addIndex?: string[],
    removeIndex?: string[],
    updateLocalValues?: any,
    disableTostify?: boolean
  ) {
    return new Promise((resolve, reject) => {
      let newVehicleData = { ...state.vehicle };

      // newVehicleData.contractData = null;
      newVehicleData.data_achizitie = moment(
        newVehicleData.data_achizitie
      ).format("YYYY-MM-DD HH:mm:ss");

      if (updateLocalValues) {
        newVehicleData = { ...newVehicleData, ...updateLocalValues };
      }

      if (newVehicleData.dotari) {
        newVehicleData.dotari = newVehicleData.dotari.map(
          (dotare: any) => dotare.id
        );
      } else {
        newVehicleData.dotari = [];
      }
      newVehicleData.indexes = updateIndex(addIndex, removeIndex);
      if (newVehicleData.id !== 0) {
        const testIndex = addIndex?.toString().replace(/[\[\]']+/g, "");

        newVehicleData.modifyBy = updateModifyBy([
          {
            index: testIndex,
            userId: localStorage.getItem("userName"),
            date: moment().format("DD-MM-YYYY HH:mm:ss"),
          },
        ]);
      }

      if (validate()) {
        const url = state.vehicle.id
          ? `${urlEnum.vehicles}/${state.vehicle?.id}${
              disableTostify ? disableTostifyConfig : ""
            }`
          : urlEnum.vehicles;
        const sendFunc = state.vehicle.id ? updateData : postData;
        sendFunc(url, newVehicleData)
          .then((response) => {
            //update after save
            if (response) {
              dispatch({
                type: actions.UPDATE_VEHICLE,
                state: response.data.data,
              });
              resolve(response.data);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  /**
   *  saves a vehicle with a file
   * @param fileType
   * @param documentType
   * @param validate
   */
  async function saveVehicleWithFile(
    parentDocArrayName: any,
    fileType: string,
    documentType: string,
    validate: () => boolean,
    addIndex?: string[],
    removeIndex?: string[],
    updateLocalValues?: any
  ) {
    return new Promise((resolve, reject) => {
      const parentDocArray = state.invoices[parentDocArrayName];
      const formData = new FormData();
      formData.append("vehicul_id", state.vehicle?.id);
      formData.append("vin", state.vehicle?.vin);
      formData.append("date", state.vehicle?.data_achizitie);
      formData.append(
        "descriere_financiar",
        ` ${parentDocArray[0] && parentDocArray[0]?.name}`
      );
      formData.append("suma_financiar", state.vehicle?.pret_achizitie_euro);
      formData.append("fileType", fileType);
      formData.append("type", documentType);
      formData.append("status_financiar", tipStatus[1].nume);
      formData.append("file_id", parentDocArray && parentDocArray[0]);

      let newVehicleData = { ...state.vehicle };
      if (newVehicleData.dotari) {
        newVehicleData.dotari = newVehicleData.dotari.map(
          (dotare: any) => dotare.id
        );
      } else {
        newVehicleData.dotari = [];
      }
      const testIndex = addIndex?.toString().replace(/[\[\]']+/g, "");
      newVehicleData.indexes = updateIndex(addIndex, removeIndex);
      newVehicleData.modifyBy = updateModifyBy([
        {
          index: testIndex,
          userId: localStorage.getItem("userName"),
          date: moment().format("DD-MM-YYYY HH:mm:ss"),
        },
      ]);

      newVehicleData.data_achizitie = moment(
        newVehicleData.data_achizitie
      ).format("YYYY-MM-DD HH:mm:ss");

      if (updateLocalValues) {
        newVehicleData = { ...newVehicleData, ...updateLocalValues };
      }

      const url = parentDocArray[0]?.id
        ? `${urlEnum.files}/${parentDocArray[0]?.id}/${state.vehicle.id}/${fileType}`
        : `${urlEnum.files}`;
      if (validate()) {
        postData(url, formData)
          .then((res) => {
            const invoices = {
              clientInvoice: res.data.clientInvoice
                ? res.data.clientInvoice
                : [],
              providerInvoice: res.data.providerInvoice
                ? res.data.providerInvoice
                : [],
              OPInvoice: res.data.OPInvoice ? res.data.OPInvoice : [],
              reservedDocuments: res.data.reservedDocuments
                ? res.data.reservedDocuments
                : [],
            };

            dispatch({
              type: actions.UPDATE_VEHICLE_INVOICES,
              state: invoices,
            });

            dispatch({
              type: actions.UPDATE_VEHICLE_DOCUMENTS,
              state: res.data.documents,
            });

            const url = `${urlEnum.vehicles}/${state.vehicle?.id}`;
            updateData(url, newVehicleData)
              .then((response) => {
                //update indexes after save
                dispatch({
                  type: actions.UPDATE_VEHICLE,
                  state: response.data.data,
                });
                resolve(true);
              })
              .catch(() => {
                reject();
              })
              .catch(() => {
                reject();
              });
          })
          .catch((error) => {
            // console.log(error);
            reject();
            toast.error(Vocabulary.error);
          });
      }
    });
  }

  /**
   * adds or remove indexes from vehicle
   * @param addIndex
   * @param removeIndex
   * @returns
   */
  function updateIndex(addIndex?: string[] | any, removeIndex?: string[]) {
    if (!state?.vehicle?.indexes)
      state.onChangeVehicle({ name: "indexes", value: [] });

    const newIndexes = new Set(state.vehicle.indexes);

    if (addIndex) {
      for (const index of addIndex) {
        newIndexes.add(index);
      }
    }

    if (removeIndex) {
      for (const index of removeIndex) {
        newIndexes.delete(index);
      }
    }
    const indexes = Array.from(newIndexes);
    dispatch({
      type: actions.ON_CHANGE_VEHICLE,
      state: { name: "indexes", value: indexes },
    });
    return indexes;
  }

  function updateModifyBy(addIndex?: any) {
    const newIndexes = new Set(state.vehicle.modifyBy);

    if (addIndex) {
      for (const index of addIndex) {
        newIndexes.add(index);
      }
    }
    const indexes = Array.from(newIndexes);
    dispatch({
      type: actions.ON_CHANGE_VEHICLE,
      state: { name: "modifyBy", value: indexes },
    });
    return indexes;
  }

  return (
    <VehicleContext.Provider value={value}>
      {props.children}
    </VehicleContext.Provider>
  );
};
