/* eslint-disable @typescript-eslint/no-explicit-any */
import GroupIcon from "@mui/icons-material/Group";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";

import DashboardIcon from "@mui/icons-material/Dashboard";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import SecurityIcon from "@mui/icons-material/Security";
import ConstructionIcon from "@mui/icons-material/Construction";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import PaidIcon from "@mui/icons-material/Paid";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { Vocabulary } from "./Vocabulary";
import {
  DINAMIC_TABS_INTERVENTII_DETAILING,
  ROUTER_INTERVENTII_DETAILING,
  VEHICLE_OPTIONS_INTERVENTII_DETAILING,
  VEHICLE_TABLE_INTERVENTII_DETAILING,
} from "./Permissions/vehicleDetailings";
import {
  DINAMIC_TABS_INTERVENTII_SERVICE,
  ROUTER_INTERVENTII_SERVICE,
  VEHICLE_OPTIONS_INTERVENTII_SERVICE,
} from "./Permissions/vehicleServices";
import {
  DINAMIC_TABS_INTERVENTII_VOPSITORIE,
  VEHICLE_OPTIONS_INTERVENTII_VOPSITORIE,
  ROUTER_INTERVENTII_VOPSITORIE,
} from "./Permissions/vehiclePaintings";
import {
  DINAMIC_TABS_COSTURI_ADITIONALE,
  MAIN_TABLE_COSTURI_ADITIONALE,
  ROUTER_COSTURI_ADITIONALE,
  VEHICLE_OPTIONS_COSTURI_ADITIONALE,
} from "./Permissions/vehicleCosts";
import {
  DINAMIC_TABS_ACCES_LOGS,
  MAIN_SLIDER_ACCES_LOGS,
  MAIN_TABLE_ACCES_LOGS,
  ROUTER_ACCES_LOGS,
  VEHICLE_OPTIONS_ACCES_LOGS,
} from "./Permissions/logs";
import {
  DINAMIC_TABS_MANAGE_UTILIZATORI,
  MAIN_SLIDER_MANAGE_UTILIZATORI,
  MAIN_TABLE_MANAGE_UTILIZATORI,
  ROUTER_MANAGE_UTILIZATORI,
  VEHICLE_OPTIONS_MANAGE_UTILIZATORI,
} from "./Permissions/users ";
import {
  DINAMIC_TABS_VIZUALIZARE_STATISTICI,
  MAIN_SLIDER_VIZUALIZARE_STATISTICI,
  MAIN_TABLE_VIZUALIZARE_STATISTICI,
  ROUTER_VIZUALIZARE_STATISTICI,
  VEHICLE_OPTIONS_VIZUALIZARE_STATISTICI,
} from "./Permissions/reports ";
import {
  DINAMIC_TABS_MANAGE_TASKURI,
  MAIN_SLIDER_MANAGE_TASKURI,
  MAIN_TABLE_MANAGE_TASKURI,
  ROUTER_MANAGE_TASKURI,
  VEHICLE_OPTIONS_MANAGE_TASKURI,
} from "./Permissions/manage-taskuri";
import {
  DINAMIC_TABS_MANAGE_COSTURI_ADMINISTRATIVE,
  MAIN_SLIDER_MANAGE_COSTURI_ADMINISTRATIVE,
  MAIN_TABLE_MANAGE_COSTURI_ADMINISTRATIVE,
  ROUTER_MANAGE_COSTURI_ADMINISTRATIVE,
  VEHICLE_OPTIONS_MANAGE_COSTURI_ADMINISTRATIVE,
} from "./Permissions/costs ";
import {
  DINAMIC_TABS_PLATFORM_SETTINGS,
  MAIN_SLIDER_PLATFORM_SETTINGS,
  MAIN_TABLE_PLATFORM_SETTINGS,
  ROUTER_PLATFORM_SETTINGS,
  VEHICLE_OPTIONS_PLATFORM_SETTINGS,
} from "./Permissions/platformSettings";
import {
  DINAMIC_TABS_RESURSE,
  MAIN_SLIDER_RESURSE,
  MAIN_TABLE_RESURSE,
  ROUTER_RESURSE,
  VEHICLE_OPTIONS_RESURSE,
} from "./Permissions/clients";
import {
  DASHBOARD_FINANCIAL_DEPARTMENT,
  DINAMIC_TABS_DASHBOARD,
  LOGIN_PAGE_DASHBOARD,
  MAIN_SLIDER_DASHBOARD,
  MAIN_TABLE_DASHBOARD,
  ROUTER_DASHBOARD,
  VEHICLE_MAIN_INFO_DASHBOARD,
  VEHICLE_OPTIONS_DASHBOARD,
} from "./Permissions/vehiclesDashboard";
import {
  DINAMIC_TABS_DOCUMENTE_FINANCIARE,
  MAIN_TABLE_DOCUMENTE_FINANCIARE,
  ROUTER_DOCUMENTE_FINANCIARE,
  VEHICLE_OPTIONS_DOCUMENTE_FINANCIARE,
} from "./Permissions/vehicleDocuments";
import {
  DINAMIC_TABS_ATRIBUIE_COMANDA_MASINA,
  MAIN_TABLE_ATRIBUIE_COMANDA_MASINA,
  ROUTER_ATRIBUIE_COMANDA_MASINA,
  VEHICLE_OPTIONS_ATRIBUIE_COMANDA_MASINA,
} from "./Permissions/atribuie-comanda-masina";
import {
  DINAMIC_TABS_VEHICULE_GESTIONARE,
  MAIN_TABLE_VEHICULE_GESTIONARE,
  ROUTER_VEHICULE_GESTIONARE,
  VEHICLE_OPTIONS_VEHICULE_GESTIONARE,
} from "./Permissions/vehicleDetails";
import {
  DINAMIC_TABS_EDITEAZA_SERVICII,
  DINAMIC_TABS_EDITEAZA_VEHICULE,
  MAIN_TABLE_EDITEAZA_VEHICULE,
  ROUTER_EDITEAZA_VEHICULE,
  VEHICLE_OPTIONS_EDITEAZA_VEHICULE,
} from "./Permissions/deleteVehicles ";
import {
  DINAMIC_TABS_ADD_VEHICLE,
  MAIN_TABLE_ADD_VEHICLE,
  ROUTER_ADD_VEHICLE,
  VEHICLE_MAIN_INFO_EDIT_VEHICLE,
  VEHICLE_OPTIONS_ADD_VEHICLE,
} from "./Permissions/editVehicles";
import { VEHICLE_MAIN_INFO_VEHICLES_RESOURCES } from "./Permissions/gestionare-firme";
import {
  DINAMIC_TABS_FINANCIAL_DEPARTMENT,
  LOGIN_PAGE_FINANCIAL_DEPARTMENT,
  MAIN_HEADER_FINANCIAL_DEPARTMENT,
  MAIN_SLIDER_FINANCIAL_DEPARTMENT,
  MAIN_TABLE_FINANCIAL_DEPARTMENT,
  ROUTER_FINANCIAL_DEPARTMENT,
  VEHICLES_DASHBOARD_FINANCIAL_DEPARTMENT,
  VEHICLE_FINANCIAL_DEPARTMENT,
} from "./Permissions/financialDepartment";
import {
  DINAMIC_TABS_SALES_DEPARTMENT,
  MAIN_HEADER_SALES,
  MAIN_SLIDER_SALES_DEPARTMENT,
  MAIN_TABLE_SALES_DEPARTMENT,
  ROUTER_SALES_DEPARTMENT,
  VEHICLES_SALES_DEPARTMENT,
  VEHICLE_OPTIONS_SALES_DEPARTMENT,
} from "./Permissions/sales";
import {
  DINAMIC_TABS_ACQUISITIONS_DEPARTMENT,
  LOGIN_PAGE_ACQUISITIONS_DEPARTMENT,
  MAIN_HEADER_ACQUISITIONS_DEPARTMENT,
  MAIN_SLIDER_ACQUISITIONS_DEPARTMENT,
  ROUTER_ACQUISITIONS_DEPARTMENT,
  VEHICLES_ACQUISITIONS_DEPARTMENT,
  VEHICLE_ACQUISITIONS_DEPARTMENT,
} from "./Permissions/acquisitions";
import {
  DINAMIC_TABS_LOGISTICS_DEPARTMENT,
  MAIN_HEADER_LOGISTICS_DEPARTMENT,
  MAIN_SLIDER_LOGISTICS_DEPARTMENT,
  MAIN_TABLE_LOGISTICS_DEPARTMENT,
  ROUTER_LOGISTICS_DEPARTMENT,
  VEHICLE_LOGISTICS_DEPARTMENT,
} from "./Permissions/logistics";
import {
  DINAMIC_TABS_GARANTII,
  ROUTER_GARANTII,
  VEHICLE_OPTIONS_GARANTII,
} from "./Permissions/manage-raportari";
import {
  DINAMIC_TABS_ACCES_TEMPLATES,
  MAIN_SLIDER_ACCES_TEMPLATES,
  MAIN_TABLE_ACCES_TEMPLATES,
  ROUTER_ACCES_TEMPLATES,
  VEHICLE_OPTIONS_ACCES_TEMPLATES,
} from "./Permissions/templates";
import { localUrlEnum } from "./urlEnum";
import {
  DINAMIC_TABS_CLIENT_MESSAGES,
  MAIN_SLIDER_CLIENT_MESSAGES,
  MAIN_TABLE_CLIENT_MESSAGES,
  ROUTER_CLIENT_MESSAGES,
  VEHICLES_CLIENT_MESSAGES,
  VEHICLE_CLIENT_MESSAGES,
} from "./Permissions/clientsMessages";
import {
  DINAMIC_TABS_PHOTOS,
  MAIN_TABLE_PHOTOS,
  ROUTER_PHOTOS,
  VEHICLE_OPTIONS_PHOTOS,
} from "./Permissions/vehiclePhotos";
import {
  DINAMIC_TABS_EDIT_STATUS,
  MAIN_TABLE_EDIT_STATUS,
  ROUTER_EDIT_STATUS,
  VEHICLES_DASHBOARD_EDIT_STATUS,
  VEHICLE_OPTIONS_EDIT_STATUS,
} from "./Permissions/editStatus";
import { isMobile } from "../Utils/utils";
import {
  MAIN_SLIDER_ANAF_DEPARTMENT,
  ROUTER_ANAF_DEPARTMENT,
} from "./Permissions/anafNotifications";
import {
  DINAMIC_TABS_HOMEPAGE,
  MAIN_SLIDER_HOMEPAGE,
  MAIN_TABLE_HOMEPAGE,
  ROUTER_HOMEPAGE,
  VEHICLE_OPTIONS_HOMEPAGE,
} from "./Permissions/homePage";
import {
  DINAMIC_TABS_SALARY,
  MAIN_SLIDER_SALARY,
  MAIN_TABLE_SALARY,
  ROUTER_SALARY,
  SALARY_COMPONENT,
  VEHICLE_OPTIONS_SALARY,
} from "./Permissions/usersSalary";
import {
  CLIENT_MESSAGES_FOR_ALL_MESSAGES,
  DASHBOARD_ACCES_FOR_ALL_MESSAGES,
  DINAMIC_TABS_ACCES_FOR_ALL_MESSAGES,
  MAIN_SLIDER_ACCES_FOR_ALL_MESSAGES,
  MAIN_TABLE_ACCES_FOR_ALL_MESSAGES,
  ROUTER_ACCES_FOR_ALL_MESSAGES,
  VEHICLE_ACCES_FOR_ALL_MESSAGES,
  VEHICLES_ACCES_FOR_ALL_MESSAGES,
} from "./Permissions/accesForAllMessages";
import {
  ROUTER_INFO_SELL_BONUS,
  VEHICLE_MAIN_INFO_SELL_BONUS,
} from "./Permissions/sellBonus";
// import {
//   DINAMIC_TABS_MARKETING_DEPARTMENT,
//   LOGIN_PAGE_MARKETING_DEPARTMENT,
//   MAIN_HEADER_MARKETING_DEPARTMENT,
//   MAIN_SLIDER_MARKETING_DEPARTMENT,
//   ROUTER_MARKETING_DEPARTMENT,
//   VEHICLES_MARKETING_DEPARTMENT,
//   VEHICLE_MARKETING_DEPARTMENT,
// } from "./Permissions/marketing";
import { VEHICLE_OPTIONS_DUPLICATE_VEHICLE } from "./Permissions/duplicateVehicle";

//NAMES FOR EACH COMPONENT
export const componentNames = {
  LocalRouter: "LocalRouter",
  DinamicTabs: "DinamicTabs",
  VehicleOptions: "VehicleOptions",
  DinamicTabsServices: "DinamicTabsServices",
  MainTable: "MainTable",
  MainHeader: "MainHeader",
  VehicleMainInfo: "VehicleMainInfo",
  MainSlider: "MainSlider",
  VehiclesDashboard: "VehiclesDashboard",
  LoginPage: "LoginPage",
  DashboardClientMessages: "DashboardClientMessages",
  ClientMessages: "ClientMessages",
  SalaryComponent: "SalaryComponent",
};

//MAIN SLIDER

export const MainSliderData = [
  {
    name: Vocabulary.usersName,
    path: "/usersDashboard",
    icon: <GroupIcon />,
  },
  {
    name: Vocabulary.clientsName,
    path: "/clients",
    icon: <GroupsIcon />,
  },
  {
    name: Vocabulary.negotiation,
    path: "/negotiation",
    icon: <ThumbsUpDownIcon />,
  },
  {
    name: Vocabulary.vehicles,
    path: "/vehiclesDashboard/",
    icon: <DirectionsCarIcon />,
  },
  {
    name: Vocabulary.tasksName,
    path: "/tasksDashboard",
    icon: <AssignmentTurnedInIcon />,
  },
  {
    name: Vocabulary.settings,
    path: "/settings",
    icon: <SettingsIcon />,
  },
  {
    name: Vocabulary.templatesName,
    path: "/templates",
    icon: <ArticleIcon />,
  },
  {
    name: Vocabulary.reportsName,
    path: "/reports",
    icon: <AssessmentIcon />,
  },
  {
    name: Vocabulary.costsName,
    path: localUrlEnum.administrativeCosts,
    icon: <RequestPageIcon />,
  },
  {
    name: Vocabulary.logsName,
    path: localUrlEnum.logs,
    icon: <AssignmentIndIcon />,
  },
];

export const DefaultVehicleSliderData = null;

//VEHICLE SLIDER
export const VehicleSliderData = [
  {
    name: Vocabulary.dashboard,
    path: `/vehiclesDashboard/vehicleDashboard/`,
    icon: <DashboardIcon />,
    id: "vehicleDashboard",
  },
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
  },
  {
    name: Vocabulary.intrareGarantie,
    path: "/vehiclesDashboard/vehicleGuarantees/",
    icon: <SecurityIcon />,
    id: "vehicleGuarantees",
    slug: "intrare_garantie",
  },
  {
    name: Vocabulary.service,
    path: "/vehiclesDashboard/vehicleService/",
    icon: <ConstructionIcon />,
    id: "vehicleService",
    slug: "interventii_service",
  },
  {
    name: Vocabulary.detailing,
    path: "/vehiclesDashboard/vehicleDetailing/",
    icon: <LocalCarWashIcon />,
    id: "vehicleDetailing",
    slug: "interventii_detailing",
  },
  {
    name: Vocabulary.vopsitorie,
    path: "/vehiclesDashboard/vehiclePainting/",
    icon: <FormatPaintIcon />,
    id: "vehiclePainting",
    slug: "interventii_vopsitorie",
  },
  {
    name: Vocabulary.costAditional,
    path: "/vehiclesDashboard/vehicleAdditionalCost/",
    icon: <PaidIcon />,
    id: "vehicleAdditionalCost",
    slug: "costuri_aditionale",
  },
  {
    name: Vocabulary.poze,
    path: "/vehiclesDashboard/vehiclePhotos/",
    icon: <PhotoLibraryIcon />,
    id: "vehiclePhotos",
    slug: "vehiclePhotos",
  },
  {
    name: Vocabulary.documente,
    path: "/vehiclesDashboard/vehicleDocuments/",
    icon: <FileCopyIcon />,
    id: "vehicleDocuments",
    slug: "vehicleDocuments",
  },
];

//ADD BUTTON PERMISSION

//TABLE OPTIONS
export const ButtonsTable = {
  route: "vehicleDashboard",
};

export const PermissionsUI: { [key: string]: any } = {
  homepage: {
    LocalRouter: ROUTER_HOMEPAGE,
    MainSlider: {
      info: MAIN_SLIDER_HOMEPAGE,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_HOMEPAGE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_HOMEPAGE,
    },
    MainTable: {
      info: MAIN_TABLE_HOMEPAGE,
    },
  },
  vehiclesDashboard: {
    LocalRouter: ROUTER_DASHBOARD,
    MainSlider: {
      info: MAIN_SLIDER_DASHBOARD,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_DASHBOARD,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_DASHBOARD,
    },
    MainTable: {
      info: MAIN_TABLE_DASHBOARD,
    },
    VehiclesDashboard: {
      info: DASHBOARD_FINANCIAL_DEPARTMENT,
    },
    LoginPage: {
      info: LOGIN_PAGE_DASHBOARD,
    },

    VehicleMainInfo: {
      info: VEHICLE_MAIN_INFO_DASHBOARD,
    },
  },
  vehicleDetailings: {
    LocalRouter: ROUTER_INTERVENTII_DETAILING,

    VehicleOptions: {
      info: VEHICLE_OPTIONS_INTERVENTII_DETAILING,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_INTERVENTII_DETAILING,
    },
    VehicleDashboardTable: {
      permission: VEHICLE_TABLE_INTERVENTII_DETAILING,
    },
  },
  vehicleServices: {
    LocalRouter: ROUTER_INTERVENTII_SERVICE,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_INTERVENTII_SERVICE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_INTERVENTII_SERVICE,
    },
  },
  vehiclePaintings: {
    LocalRouter: ROUTER_INTERVENTII_VOPSITORIE,

    VehicleOptions: {
      info: VEHICLE_OPTIONS_INTERVENTII_VOPSITORIE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_INTERVENTII_VOPSITORIE,
    },
  },
  vehicleCosts: {
    LocalRouter: ROUTER_COSTURI_ADITIONALE,

    VehicleOptions: {
      info: VEHICLE_OPTIONS_COSTURI_ADITIONALE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_COSTURI_ADITIONALE,
    },
    MainTable: {
      info: MAIN_TABLE_COSTURI_ADITIONALE,
    },
  },
  logs: {
    LocalRouter: ROUTER_ACCES_LOGS,
    MainSlider: {
      info: MAIN_SLIDER_ACCES_LOGS,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_ACCES_LOGS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ACCES_LOGS,
    },
    MainTable: {
      info: MAIN_TABLE_ACCES_LOGS,
    },
  },
  users: {
    LocalRouter: ROUTER_MANAGE_UTILIZATORI,
    MainSlider: {
      info: MAIN_SLIDER_MANAGE_UTILIZATORI,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_MANAGE_UTILIZATORI,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_MANAGE_UTILIZATORI,
    },
    MainTable: {
      info: MAIN_TABLE_MANAGE_UTILIZATORI,
    },
  },
  reports: {
    LocalRouter: ROUTER_VIZUALIZARE_STATISTICI,
    MainSlider: {
      info: MAIN_SLIDER_VIZUALIZARE_STATISTICI,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_VIZUALIZARE_STATISTICI,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_VIZUALIZARE_STATISTICI,
    },
    MainTable: {
      info: MAIN_TABLE_VIZUALIZARE_STATISTICI,
    },
  },
  tasks: {
    LocalRouter: ROUTER_MANAGE_TASKURI,
    MainSlider: {
      info: MAIN_SLIDER_MANAGE_TASKURI,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_MANAGE_TASKURI,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_MANAGE_TASKURI,
    },
    MainTable: {
      info: MAIN_TABLE_MANAGE_TASKURI,
    },
  },
  costs: {
    LocalRouter: ROUTER_MANAGE_COSTURI_ADMINISTRATIVE,
    MainSlider: {
      info: MAIN_SLIDER_MANAGE_COSTURI_ADMINISTRATIVE,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_MANAGE_COSTURI_ADMINISTRATIVE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_MANAGE_COSTURI_ADMINISTRATIVE,
    },
    MainTable: {
      info: MAIN_TABLE_MANAGE_COSTURI_ADMINISTRATIVE,
    },
  },
  usersSalary: {
    LocalRouter: ROUTER_SALARY,
    MainSlider: {
      info: MAIN_SLIDER_SALARY,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_SALARY,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_SALARY,
    },
    MainTable: {
      info: MAIN_TABLE_SALARY,
    },
    SalaryComponent: {
      info: SALARY_COMPONENT,
    },
  },
  // TOATE SETARILE VIN ACUM DIN PLATFOMR SETTINGS PENTRU TOATE PERMISIUNILE DE SETARI! A SE EDITA MAI JOS DACA SE VREA PE FIECARE SETARE IN PARTE

  platformSettings: {
    LocalRouter: ROUTER_PLATFORM_SETTINGS,
    MainSlider: {
      info: MAIN_SLIDER_PLATFORM_SETTINGS,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_PLATFORM_SETTINGS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_PLATFORM_SETTINGS,
    },
    MainTable: {
      info: MAIN_TABLE_PLATFORM_SETTINGS,
    },
  },
  statusSettings: {
    LocalRouter: ROUTER_PLATFORM_SETTINGS,
    MainSlider: {
      info: MAIN_SLIDER_PLATFORM_SETTINGS,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_PLATFORM_SETTINGS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_PLATFORM_SETTINGS,
    },
    MainTable: {
      info: MAIN_TABLE_PLATFORM_SETTINGS,
    },
  },
  rolesSettings: {
    LocalRouter: ROUTER_PLATFORM_SETTINGS,
    MainSlider: {
      info: MAIN_SLIDER_PLATFORM_SETTINGS,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_PLATFORM_SETTINGS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_PLATFORM_SETTINGS,
    },
    MainTable: {
      info: MAIN_TABLE_PLATFORM_SETTINGS,
    },
  },
  permissions: {
    LocalRouter: ROUTER_PLATFORM_SETTINGS,
    MainSlider: {
      info: MAIN_SLIDER_PLATFORM_SETTINGS,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_PLATFORM_SETTINGS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_PLATFORM_SETTINGS,
    },
    MainTable: {
      info: MAIN_TABLE_PLATFORM_SETTINGS,
    },
  },
  clients: {
    LocalRouter: ROUTER_RESURSE,
    MainSlider: {
      info: MAIN_SLIDER_RESURSE,
    },
    VehicleOptions: {
      info: VEHICLE_OPTIONS_RESURSE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_RESURSE,
    },
    MainTable: {
      info: MAIN_TABLE_RESURSE,
    },
  },
  vehicleDocuments: {
    LocalRouter: ROUTER_DOCUMENTE_FINANCIARE,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_DOCUMENTE_FINANCIARE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_DOCUMENTE_FINANCIARE,
    },
    MainTable: {
      info: MAIN_TABLE_DOCUMENTE_FINANCIARE,
    },
  },
  vehiclePhotos: {
    LocalRouter: ROUTER_PHOTOS,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_PHOTOS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_PHOTOS,
    },
    MainTable: {
      info: MAIN_TABLE_PHOTOS,
    },
  },
  "atribuie-comanda-masina": {
    LocalRouter: ROUTER_ATRIBUIE_COMANDA_MASINA,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_ATRIBUIE_COMANDA_MASINA,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ATRIBUIE_COMANDA_MASINA,
    },
    MainTable: {
      info: MAIN_TABLE_ATRIBUIE_COMANDA_MASINA,
    },
  },
  vehicleDetails: {
    LocalRouter: ROUTER_VEHICULE_GESTIONARE,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_VEHICULE_GESTIONARE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_VEHICULE_GESTIONARE,
    },
    MainTable: {
      info: MAIN_TABLE_VEHICULE_GESTIONARE,
    },
  },
  deleteVehicles: {
    LocalRouter: ROUTER_EDITEAZA_VEHICULE,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_EDITEAZA_VEHICULE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_EDITEAZA_VEHICULE,
    },
    DinamicTabsServices: {
      info: DINAMIC_TABS_EDITEAZA_SERVICII,
    },
    MainTable: {
      info: MAIN_TABLE_EDITEAZA_VEHICULE,
    },
  },
  editVehicles: {
    LocalRouter: ROUTER_ADD_VEHICLE,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_ADD_VEHICLE,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ADD_VEHICLE,
    },
    MainTable: {
      info: MAIN_TABLE_ADD_VEHICLE,
    },
    VehicleMainInfo: {
      info: VEHICLE_MAIN_INFO_EDIT_VEHICLE,
    },
  },
  duplicateVehicle: {
    LocalRouter: ROUTER_EDITEAZA_VEHICULE,
    VehiclesDashboard: {
      permission: VEHICLE_OPTIONS_DUPLICATE_VEHICLE,
    },
    VehicleOptions: { permission: VEHICLE_OPTIONS_DUPLICATE_VEHICLE },
    MainTable: { permission: VEHICLE_OPTIONS_DUPLICATE_VEHICLE },
  },
  editStatus: {
    LocalRouter: ROUTER_EDIT_STATUS,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_EDIT_STATUS,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_EDIT_STATUS,
    },
    MainTable: {
      info: MAIN_TABLE_EDIT_STATUS,
    },
    VehiclesDashboard: {
      info: VEHICLES_DASHBOARD_EDIT_STATUS,
    },
  },

  vehicleGuarantees: {
    LocalRouter: ROUTER_GARANTII,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_GARANTII,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_GARANTII,
    },
  },

  "gestionare-firme": {
    VehicleMainInfo: {
      info: VEHICLE_MAIN_INFO_VEHICLES_RESOURCES,
    },
  },
  financialDepartment: {
    LocalRouter: ROUTER_FINANCIAL_DEPARTMENT,
    VehicleOptions: {
      info: VEHICLE_FINANCIAL_DEPARTMENT,
    },
    MainSlider: {
      info: MAIN_SLIDER_FINANCIAL_DEPARTMENT,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_FINANCIAL_DEPARTMENT,
    },
    MainTable: {
      info: MAIN_TABLE_FINANCIAL_DEPARTMENT,
    },
    VehiclesDashboard: {
      info: VEHICLES_DASHBOARD_FINANCIAL_DEPARTMENT,
    },
    LoginPage: {
      info: LOGIN_PAGE_FINANCIAL_DEPARTMENT,
    },
    MainHeader: {
      info: MAIN_HEADER_FINANCIAL_DEPARTMENT,
    },
  },

  clientsMessages: {
    LocalRouter: ROUTER_CLIENT_MESSAGES,
    VehicleOptions: {
      info: VEHICLE_CLIENT_MESSAGES,
    },
    MainSlider: {
      info: MAIN_SLIDER_CLIENT_MESSAGES,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_CLIENT_MESSAGES,
    },
    MainTable: {
      info: MAIN_TABLE_CLIENT_MESSAGES,
    },
    VehiclesDashboard: {
      info: VEHICLES_CLIENT_MESSAGES,
    },
  },

  sales: {
    LocalRouter: ROUTER_SALES_DEPARTMENT,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_SALES_DEPARTMENT,
    },
    MainSlider: {
      info: MAIN_SLIDER_SALES_DEPARTMENT,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_SALES_DEPARTMENT,
    },
    MainTable: {
      info: MAIN_TABLE_SALES_DEPARTMENT,
    },
    VehiclesDashboard: {
      info: VEHICLES_SALES_DEPARTMENT,
    },
    MainHeader: {
      info: MAIN_HEADER_SALES,
    },
  },
  acquisitions: {
    LocalRouter: ROUTER_ACQUISITIONS_DEPARTMENT,
    VehicleOptions: {
      info: VEHICLE_ACQUISITIONS_DEPARTMENT,
    },
    MainSlider: {
      info: MAIN_SLIDER_ACQUISITIONS_DEPARTMENT,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ACQUISITIONS_DEPARTMENT,
    },
    VehiclesDashboard: {
      info: VEHICLES_ACQUISITIONS_DEPARTMENT,
    },
    LoginPage: {
      info: LOGIN_PAGE_ACQUISITIONS_DEPARTMENT,
    },
    MainHeader: {
      info: MAIN_HEADER_ACQUISITIONS_DEPARTMENT,
    },
  },
  // marketing: {
  //   LocalRouter: ROUTER_MARKETING_DEPARTMENT,
  //   VehicleOptions: {
  //     info: VEHICLE_MARKETING_DEPARTMENT,
  //   },
  //   MainSlider: {
  //     info: MAIN_SLIDER_MARKETING_DEPARTMENT,
  //   },
  //   DinamicTabs: {
  //     info: DINAMIC_TABS_MARKETING_DEPARTMENT,
  //   },
  //   VehiclesDashboard: {
  //     info: VEHICLES_MARKETING_DEPARTMENT,
  //   },
  //   LoginPage: {
  //     info: LOGIN_PAGE_MARKETING_DEPARTMENT,
  //   },
  //   MainHeader: {
  //     info: MAIN_HEADER_MARKETING_DEPARTMENT,
  //   },
  // },
  logistics: {
    LocalRouter: ROUTER_LOGISTICS_DEPARTMENT,
    VehicleOptions: {
      info: VEHICLE_LOGISTICS_DEPARTMENT,
    },
    MainSlider: {
      info: MAIN_SLIDER_LOGISTICS_DEPARTMENT,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_LOGISTICS_DEPARTMENT,
    },
    VehiclesDashboard: {
      info: VEHICLE_LOGISTICS_DEPARTMENT,
    },
    MainTable: {
      info: MAIN_TABLE_LOGISTICS_DEPARTMENT,
    },
    MainHeader: {
      info: MAIN_HEADER_LOGISTICS_DEPARTMENT,
    },
  },
  anaf: {
    LocalRouter: ROUTER_ANAF_DEPARTMENT,
    MainSlider: {
      info: MAIN_SLIDER_ANAF_DEPARTMENT,
    },
  },
  accesForAllMessages: {
    LocalRouter: ROUTER_ACCES_FOR_ALL_MESSAGES,
    VehicleOptions: {
      info: VEHICLE_ACCES_FOR_ALL_MESSAGES,
    },
    MainSlider: {
      info: MAIN_SLIDER_ACCES_FOR_ALL_MESSAGES,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ACCES_FOR_ALL_MESSAGES,
    },
    MainTable: {
      info: MAIN_TABLE_ACCES_FOR_ALL_MESSAGES,
    },
    VehiclesDashboard: {
      info: VEHICLES_ACCES_FOR_ALL_MESSAGES,
    },
    DashboardClientMessages: {
      info: DASHBOARD_ACCES_FOR_ALL_MESSAGES,
    },
    ClientMessages: {
      info: CLIENT_MESSAGES_FOR_ALL_MESSAGES,
    },
  },

  sellBonus: {
    LocalRouter: ROUTER_INFO_SELL_BONUS,
    VehicleMainInfo: {
      info: VEHICLE_MAIN_INFO_SELL_BONUS,
    },
  },

  // PERMISIUNE ASTA ESTE PENTRU ACCESUL LA TEMPLATES//
  // IN MOMENTUL DE FATA NU ERA O PERMISIUNE PENTRU TEMPLATES//
  // AM FOLOSIT UNA EXISTENTA PENTRU A NU CREA O NOUA PERMISIUNE DEOARECE ORICUM TREBUIE SCHIMBATE//
  // NUMELE PERMISIUNII NU ARE NICIO LEGATURA CU CE TREBUIE SA FACA//

  templates: {
    LocalRouter: ROUTER_ACCES_TEMPLATES,
    VehicleOptions: {
      info: VEHICLE_OPTIONS_ACCES_TEMPLATES,
    },
    MainSlider: {
      info: MAIN_SLIDER_ACCES_TEMPLATES,
    },
    DinamicTabs: {
      info: DINAMIC_TABS_ACCES_TEMPLATES,
    },
    MainTable: {
      info: MAIN_TABLE_ACCES_TEMPLATES,
    },
  },
};

export const TemplatesType = [
  { id: 1, name: "Contract" },
  { id: 2, name: "Anunt" },
  { id: 3, name: "Proforma" },
  { id: 4, name: "GDPR" },
  { id: 5, name: "Antecontract" },
];

export const AUTODELRULATE_LINK = "https://autodelrulate.ro/";
export const AUTODELRULATE_AD_LINK = "https://autodelrulate.ro/anunt/";
export const PER_PAGE_DEFAULT = 15;
export const INACTIVITY_TIMEOUT = 1000 * 60 * 20;

export const RolesEnum = {
  admin: "Administrator",
  saleDepartment: "Sef departament vanzari",
  financialDepartment: "Sef departament financiar",
  logisticsDepartment: "Sef departament logistica",
  acquisitionsDepartment: "Sef departament achizitii",
};
export const FIRST_SEEN_USER_ID_NOT_ALLOCATED = '{"first_seen_user_id":null}';

export const clientMessagesStatus = {
  Toate: "all",
  Nealocate: "unassigned",
  Anulate: "canceled",
  Alocate: "assigned",
};

export const DEFAULT_MAX_NUMBER_OF_MESSAGES = 20;

export const CarOrderSource = {
  manualOrder: Vocabulary.manualCarOrder, //default value
  siteOrder: Vocabulary.siteCarOrder,
};

export const Settings = {
  maxNumberForMessages: "maxNumberForMessages",
  automatedClientMessages: "automatedClientMessages",
  notificationSettings: "notificationSettings",
};

export const tipStatusVehicule = {
  Vehicle: "Vehicul",
  CarOrder: "Comanda",
  CarRequest: "MasinaComanda",
  Template: "Template",
  ClientMessages: "MesajeClienti",
};

export const portraitLayout = window.matchMedia("(orientation: portrait)");

// export const tableLayoutOnMobile = isMobile() ? "simple" : ("standard" as any);
export const tableLayoutOnMobile =
  isMobile() && portraitLayout.matches ? "simple" : ("standard" as any);

export const euCountries = [
  { name: "Austria", code: "AT" },
  { name: "Belgia", code: "BE" },
  { name: "Bulgaria", code: "BG" },
  { name: "Croatia", code: "HR" },
  { name: "Cipru", code: "CY" },
  { name: "Cehia", code: "CZ" },
  { name: "Danemarca", code: "DK" },
  { name: "Estonia", code: "EE" },
  { name: "Finlanda", code: "FI" },
  { name: "Franta", code: "FR" },
  { name: "Germania", code: "DE" },
  { name: "Grecia", code: "GR" },
  { name: "Ungaria", code: "HU" },
  { name: "Irlanda", code: "IE" },
  { name: "Italia", code: "IT" },
  { name: "Latvia", code: "LV" },
  { name: "Lituania", code: "LT" },
  { name: "Luxemburg", code: "LU" },
  { name: "Malta", code: "MT" },
  { name: "Olanda", code: "NL" },
  { name: "Polonia", code: "PL" },
  { name: "Portugalia", code: "PT" },
  { name: "Romania", code: "RO" },
  { name: "Slovacia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Spania", code: "ES" },
  { name: "Suedia", code: "SE" },
];

export interface VameRomaniaOption {
  id: number;
  cod: number;
  denumire: string;
  judCod: number;
}

export const vameRomania: VameRomaniaOption[] = [
  {
    id: 32,
    cod: 32,
    denumire: "Albița",
    judCod: 37,
  },
  {
    id: 6,
    cod: 6,
    denumire: "Bechet",
    judCod: 16,
  },
  {
    id: 2,
    cod: 2,
    denumire: "Borș",
    judCod: 5,
  },
  {
    id: 38,
    cod: 38,
    denumire: "Borș 2 - A3",
    judCod: 5,
  },
  {
    id: 5,
    cod: 5,
    denumire: "Calafat",
    judCod: 16,
  },
  {
    id: 16,
    cod: 16,
    denumire: "Carei",
    judCod: 30,
  },
  {
    id: 17,
    cod: 17,
    denumire: "Cenad",
    judCod: 35,
  },
  {
    id: 35,
    cod: 35,
    denumire: "Constanța Sud Agigea",
    judCod: 13,
  },
  {
    id: 14,
    cod: 14,
    denumire: "Corabia",
    judCod: 28,
  },
  {
    id: 13,
    cod: 13,
    denumire: "Călărași (Chiciu)",
    judCod: 13,
  },
  {
    id: 18,
    cod: 18,
    denumire: "Episcopia Bihor",
    judCod: 5,
  },
  {
    id: 34,
    cod: 34,
    denumire: "Galați Giurgiulești",
    judCod: 17,
  },
  {
    id: 9,
    cod: 9,
    denumire: "Giurgiu",
    judCod: 52,
  },
  {
    id: 29,
    cod: 29,
    denumire: "Halmeu",
    judCod: 30,
  },
  {
    id: 28,
    cod: 28,
    denumire: "Jimbolia",
    judCod: 35,
  },
  {
    id: 26,
    cod: 26,
    denumire: "Naidăș",
    judCod: 11,
  },
  {
    id: 11,
    cod: 11,
    denumire: "Negru Vodă",
    judCod: 13,
  },
  {
    id: 4,
    cod: 4,
    denumire: "Nădlac",
    judCod: 2,
  },
  {
    id: 37,
    cod: 37,
    denumire: "Nădlac 2 - A1",
    judCod: 2,
  },
  {
    id: 33,
    cod: 33,
    denumire: "Oancea",
    judCod: 17,
  },
  {
    id: 15,
    cod: 15,
    denumire: "Oltenița",
    judCod: 51,
  },
  {
    id: 10,
    cod: 10,
    denumire: "Ostrov",
    judCod: 13,
  },
  {
    id: 1,
    cod: 1,
    denumire: "Petea",
    judCod: 30,
  },
  {
    id: 25,
    cod: 25,
    denumire: "Porțile de Fier 1",
    judCod: 25,
  },
  {
    id: 19,
    cod: 19,
    denumire: "Salonta",
    judCod: 5,
  },
  {
    id: 31,
    cod: 31,
    denumire: "Sculeni",
    judCod: 22,
  },
  {
    id: 36,
    cod: 36,
    denumire: "Siret ",
    judCod: 33,
  },
  {
    id: 27,
    cod: 27,
    denumire: "Stamora Moravița",
    judCod: 35,
  },
  {
    id: 30,
    cod: 30,
    denumire: "Stânca Costești",
    judCod: 7,
  },
  {
    id: 20,
    cod: 20,
    denumire: "Săcuieni",
    judCod: 5,
  },
  {
    id: 21,
    cod: 21,
    denumire: "Turnu",
    judCod: 2,
  },
  {
    id: 7,
    cod: 7,
    denumire: "Turnu Măgurele",
    judCod: 34,
  },
  {
    id: 22,
    cod: 22,
    denumire: "Urziceni",
    judCod: 30,
  },
  {
    id: 23,
    cod: 23,
    denumire: "Valea lui Mihai",
    judCod: 5,
  },
  {
    id: 12,
    cod: 12,
    denumire: "Vama Veche",
    judCod: 13,
  },
  {
    id: 24,
    cod: 24,
    denumire: "Vladimirescu",
    judCod: 2,
  },
  {
    id: 3,
    cod: 3,
    denumire: "Vărșand",
    judCod: 2,
  },
  {
    id: 8,
    cod: 8,
    denumire: "Zimnicea",
    judCod: 34,
  },
];

export const actionTypeForGeneralModal = {
  duplicate: "duplicate",
  delete: "delete",
};
