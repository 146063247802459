/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  ClickAwayListener,
  IconButton,
  Typography,
} from "@mui/material";
import style from "../Styles/vehicleOptions.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { urlEnum } from "../Utils/urlEnum";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { isMobile } from "../Utils/utils";
import withRole from "../Utils/withRole";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { VehicleContext } from "../Context/vehicleContext";
import FilePreview from "../Utils/FilePreview";
import CloseIcon from "@mui/icons-material/Close";
import { Vocabulary } from "../Utils/Vocabulary";
import GenericModal from "../Modal/GenericModal";
import { deleteData } from "../Services/deleteData";
import DuplicateVehicle from "./ProgressActions/DuplicateVehicle";

function VehicleOptions(
  props: {
    info?: any;
    editButton?: boolean;
    handleEdit?: any;
    editText?: string;
    permission?: any;
  },
  location: any
) {
  const vehicleContext: any = useContext(VehicleContext);
  const [showOptions, setShowOptions]: any = useState(
    isMobile() ? true : false
  );
  const [avatarImage, setAvatarImage]: any = useState();
  const [openDeleteModal, setOpenDeleteModal]: any = useState(false);
  location = useLocation();
  const navigate = useNavigate();

  const test = location.pathname;
  const words = test.split("/");
  const id = words[words.length - 1];
  const path = words[2];

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      top: 12,
      left: -51,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "#fff",
    },
  }));

  /**
   *
   * @param id
   */
  const submitDeletedCar = (id: any) => {
    deleteVehicle(id);
  };

  /**
   *
   * @param id
   */
  async function deleteVehicle(id: any) {
    const url = `${urlEnum.vehicles}/${id}`;
    const res = (await deleteData(url)) as any;
    if (!res || res?.error) {
      return;
    } else {
      setOpenDeleteModal(false);
      navigate("/");
    }
  }

  useEffect(() => {
    setAvatarImage(
      vehicleContext?.state?.images && vehicleContext?.state?.images[0]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext?.state?.images]);

  const getNumberOfBadge = (element: any) => {
    switch (element.id) {
      case "vehiclePhotos":
        return vehicleContext?.state?.images?.length > 0
          ? vehicleContext?.state?.images?.length
          : 0;

      case "vehicleDocuments":
        return vehicleContext?.state?.documents?.length > 0
          ? vehicleContext?.state?.documents?.length
          : 0;
      default:
        return element.slug
          ? vehicleContext?.state?.vehicle?.[element.slug]?.length > 0
            ? vehicleContext?.state?.vehicle?.[element.slug]?.length
            : 0
          : null;
    }
  };

  return (
    <ClickAwayListener
      key={showOptions}
      onClickAway={isMobile() ? () => setShowOptions(true) : () => null}
      style={{ zIndex: 6000 }}
    >
      <div>
        {isMobile() ? (
          <IconButton
            size="small"
            className={style.closeButton}
            aria-label="add"
            onClick={() => setShowOptions(!showOptions)}
          >
            {showOptions ? <DirectionsCarIcon /> : <CloseIcon />}
          </IconButton>
        ) : (
          <></>
        )}
        {isMobile() ? (
          <div
            className={!showOptions ? style.overlay : style.overlayHide}
            onClick={() => setShowOptions(!showOptions)}
          >
            <div
              className={
                showOptions
                  ? style.containerOptionsHide
                  : style.containerOptions
              }
            >
              <div style={{ display: "flex" }}>
                {avatarImage ? (
                  <Avatar
                    style={{
                      marginRight: 10,
                      marginLeft: -10,
                      borderRadius: 5,
                      marginBottom: 5,
                      cursor: "pointer",
                    }}
                    sx={{ width: 56, height: 56 }}
                    alt="Autodel"
                    src={`/api/${avatarImage?.file}`}
                    data-file={`${urlEnum.vehicles}/${avatarImage?.file}`}
                  />
                ) : (
                  <Avatar
                    style={{
                      marginRight: 10,
                      marginLeft: -10,
                      borderRadius: 5,
                      marginBottom: 5,
                    }}
                    sx={{ width: 56, height: 56 }}
                  >
                    <ImageNotSupportedIcon></ImageNotSupportedIcon>
                  </Avatar>
                )}

                <div>
                  <Typography
                    style={{
                      fontSize: 17,
                      color: "#4d5e80",
                      fontWeight: 800,
                    }}
                  >
                    {vehicleContext?.state?.vehicle?.numeComplet
                      ? vehicleContext?.state?.vehicle?.numeComplet
                      : "Vehicul Nou"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "#4d5e80",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {vehicleContext?.state?.vehicle?.vin
                      ? vehicleContext?.state?.vehicle?.vin
                      : "Adauga detalii vehicul"}
                  </Typography>
                </div>
              </div>
              <div  style={{ display: "flex", gap: "10px" }}>
                {props?.info?.find((info: any) => {
                  if (info.delete) return true;
                }) ? (
                  words[words.length - 1] !== "newVehicle" ? (
                    <Button
                      style={{
                        color: "#f33",
                        display: "flex",
                        border: "solid 1px #f33",
                        marginBottom: 10,
                        fontSize: 9,
                      }}
                      onClick={(e: any) => {
                        setOpenDeleteModal(true);
                      }}
                    >
                      {Vocabulary.deleteCar}
                    </Button>
                  ) : null
                ) : null}

                {props?.permission?.duplicateVehicle && <DuplicateVehicle />}
              </div>
              <Divider></Divider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  height: "100%",
                }}
              >
                {props?.info
                  ?.sort((a: any, b: any) =>
                    a?.orderNumber > b?.orderNumber ? 1 : -1
                  )
                  .map((element: any, key: any) => {
                    return (
                      props?.info[key] && (
                        <Link
                          onClick={() =>
                            isMobile() ? setShowOptions(!showOptions) : null
                          }
                          key={key}
                          className={
                            id === "newVehicle" ? style.disableLink : style.link
                          }
                          to={element.path + id}
                        >
                          <div
                            className={
                              path === element.id
                                ? style.optionsSelected
                                : style.option
                            }
                          >
                            <div className={style.icon}>{element.icon}</div>
                            <StyledBadge
                              showZero={false}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              badgeContent={getNumberOfBadge(element)}
                              color="primary"
                            >
                              <Typography
                                className={
                                  location.pathname === element.path
                                    ? style.selectedText
                                    : style.nonSelectedText
                                }
                              >
                                {element.name}
                              </Typography>
                            </StyledBadge>
                          </div>
                        </Link>
                      )
                    );
                  })}
              </div>
              <div id="button_portal"></div>
            </div>
          </div>
        ) : (
          <div style={{ margin: 20 }}>
            <div style={{ display: "flex" }}>
              {avatarImage ? (
                <Avatar
                  style={{
                    marginRight: 10,
                    marginLeft: -10,
                    borderRadius: 5,
                    marginBottom: 5,
                    cursor: "pointer",
                  }}
                  sx={{ width: 75, height: 75 }}
                  alt="Autodel"
                  src={`/api/${avatarImage?.file}`}
                  data-file={`${urlEnum.vehicles}/${avatarImage?.file}`}
                />
              ) : (
                <Avatar
                  style={{
                    marginRight: 10,
                    marginLeft: -10,
                    borderRadius: 5,
                    marginBottom: 5,
                  }}
                  sx={{ width: 75, height: 75 }}
                >
                  <ImageNotSupportedIcon></ImageNotSupportedIcon>
                </Avatar>
              )}

              <div>
                <Typography
                  style={{
                    fontSize: 17,
                    color: "#4d5e80",
                    fontWeight: 800,
                  }}
                >
                  {vehicleContext?.state?.vehicle?.numeComplet
                    ? vehicleContext?.state?.vehicle?.numeComplet
                    : "Vehicul Nou"}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#4d5e80",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {vehicleContext?.state?.vehicle?.vin
                    ? vehicleContext?.state?.vehicle?.vin
                    : "Adauga detalii vehicul"}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {props?.info?.find((info: any) => {
                if (info.delete) return true;
              }) ? (
                words[words.length - 1] !== "newVehicle" ? (
                  <Button
                    style={{
                      color: "#f33",
                      display: "flex",
                      border: "solid 1px #f33",
                      marginBottom: 10,
                      fontSize: 9,
                    }}
                    onClick={(e: any) => {
                      setOpenDeleteModal(true);
                    }}
                  >
                    {Vocabulary.deleteCar}
                  </Button>
                ) : null
              ) : null}
              {props?.permission?.duplicateVehicle && <DuplicateVehicle />}
            </div>
            <Divider></Divider>
            <div
              style={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexWrap: "wrap",
                height: "100%",
              }}
            >
              {props?.info
                ?.sort((a: any, b: any) =>
                  a?.orderNumber > b?.orderNumber ? 1 : -1
                )
                .map((element: any, key: any) => {
                  return (
                    props?.info[key] && (
                      <Link
                        onClick={() =>
                          isMobile() ? setShowOptions(!showOptions) : null
                        }
                        key={key}
                        className={
                          id === "newVehicle" ? style.disableLink : style.link
                        }
                        to={element.path + id}
                      >
                        <div
                          className={
                            path === element.id
                              ? style.optionsSelected
                              : style.option
                          }
                        >
                          <div className={style.icon}>{element.icon}</div>
                          <StyledBadge
                            showZero={false}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            badgeContent={getNumberOfBadge(element)}
                            color="primary"
                          >
                            <Typography
                              className={
                                location.pathname === element.path
                                  ? style.selectedText
                                  : style.nonSelectedText
                              }
                            >
                              {element.name}
                            </Typography>
                          </StyledBadge>
                        </div>
                      </Link>
                    )
                  );
                })}
            </div>
            <div id="button_portal"></div>
          </div>
        )}

        <FilePreview
          key={
            vehicleContext?.state?.invoices?.OPInvoice.length +
            vehicleContext?.state?.invoices?.clientInvoice.length +
            vehicleContext?.state?.invoices?.providerInvoice.length +
            vehicleContext?.state?.invoices?.reservedDocuments.length +
            (vehicleContext?.state?.vehicle?.costuri_aditionale
              ? vehicleContext?.state?.vehicle?.costuri_aditionale?.length
              : 0) +
            (vehicleContext?.state?.documents
              ? vehicleContext?.state?.documents?.length
              : 0) +
            (vehicleContext?.state?.vehicle?.interventii_vopsitorie
              ? vehicleContext?.state?.vehicle?.interventii_vopsitorie.length
              : 0) +
            (vehicleContext?.state?.vehicle?.interventii_detailing
              ? vehicleContext?.state?.vehicle?.interventii_detailing.length
              : 0) +
            (vehicleContext?.state?.vehicle?.intrare_garantie
              ? vehicleContext?.state?.vehicle?.intrare_garantie.length
              : 0) +
            (vehicleContext?.state?.vehicle?.interventii_service
              ? vehicleContext?.state?.vehicle?.interventii_service.length
              : 0)
          }
        />
        <GenericModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title={Vocabulary.confirmDelete}
          buttons={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
              >
                {Vocabulary.no}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  submitDeletedCar(id);
                }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {Vocabulary.askForDeleteVehicle}
          </div>
        </GenericModal>
      </div>
    </ClickAwayListener>
  );
}

export default withRole(VehicleOptions);
