/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useState } from "react";
import { VehicleContext } from "../../Context/vehicleContext";

import GenericModal from "../../Modal/GenericModal";
import { ContractInformationModel } from "../../Models/Models";

import { updateData } from "../../Services/updateData";
import { CacheContext } from "../../Context/cacheContext";
import { VehiclesStatus } from "../../Utils/autocompleteEnum";
import { urlEnum } from "../../Utils/urlEnum";
import { Button, Grid } from "@mui/material";

export default function CancelSell() {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const [open, setOpen] = useState(false);

  /**
   * unreserve vehicle
   */
  async function unReserveVehicle() {
    const status = cacheContext.cache?.vehicleStatus?.find(
      (status: any) => status.nume === VehiclesStatus.received
    );

    const data = {
      ...vehicleContext.state.vehicle,
      client_id: null,
      data_rezervare: null,
      data_vanzare: null,
      vanzator_id: null,
      status_id: status.id,
      isLeasing: false,
      contractData: new ContractInformationModel(),
      indexes: vehicleContext.updateIndex(null, [
        "3.1",
        "3.2",
        "3.3",
        "3.4",
        "4.1",
        "4.2",
        "4.3",
        "4.4",
      ]),
    };

    // unreserve vehicle
    const url = `${urlEnum.cancelTheSale}/${vehicleContext?.state?.vehicle?.id}`;

    const response = await updateData(url, data);

    //update documents;
    vehicleContext.updateVehicle(response?.data?.vehicle);
    vehicleContext.updateVehicleDocuments(response?.data?.documents);
    vehicleContext.updateVehicleInvoices({
      clientInvoice: response.data.clientInvoice
        ? response.data.clientInvoice
        : [],
      providerInvoice: response.data.providerInvoice
        ? response.data.providerInvoice
        : [],
      OPInvoice: response.data.OPInvoice ? response.data.OPInvoice : [],
      reservedDocuments: response.data.reservedDocuments
        ? response.data.reservedDocuments
        : [],
    });
    setOpen(!open);
  }

  return (
    <div>
      <Button
        variant="contained"
        style={{
          margin: 10,
          color: "#fff",
          backgroundColor: "#f33",
          float: "right",
        }}
        onClick={() => setOpen(!open)}
      >
        {Vocabulary.cancelSell}
      </Button>
      <GenericModal
        open={open}
        onClose={() => setOpen(!open)}
        title={Vocabulary.cancelSellVehicleMessage}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Button
              size="large"
              onClick={() => setOpen(!open)}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={unReserveVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
