/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from "react";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import GestureIcon from "@material-ui/icons/Gesture";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { download } from "../../Utils/utils";
import EmailForm from "./EmailForm";
import { urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import { ClientData } from "../Clients/Clients";
import { getData } from "../../Services/getData";
import { CacheContext } from "../../Context/cacheContext";
import SignatureModal from "./SignatureModal";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../Styles/genericModal.module.css";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

type PreviewDocumentProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  id?: any;
  url?: string;
  emailData?: {
    to: string;
    subject: string;
    message: string;
    docPath?: string;
  };
  signature?: boolean;
  customTopMenu?: JSX.Element | null | undefined;
  isSign?: boolean | undefined;
  resetClientFilePathForGDPR?: (client: ClientData | null) => void;
  firmaId?: any;
  doc?: any;
};

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  actionsWrapper: {
    background: "#ff6633",
    zIndex: 100,
  },
  overflowHidden: {
    overflow: "hidden",
  },
  page: {
    margin: "10px 0px 10px 10px",
    border: "1px solid #ccc",
  },
}));

type ViewerState = {
  numPages: number;
  pdfPath: File | null;
  templateLoaded: number;
  pageNumber: number;
  scale: number;
  pdfLink: string;
  showEmail: boolean;
  emailSent: boolean;
  showSignature: boolean;
};

function PreviewPDF(props: PreviewDocumentProps) {
  const {
    url,
    id,
    open,
    onClose,
    title,
    signature,
    isSign,
    emailData,
    customTopMenu,
    doc,
    firmaId,
    resetClientFilePathForGDPR,
  } = props;
  const cacheContext: any = useContext(CacheContext);
  const classes = useStyles();
  const contentRef: any = useRef(null);
  const embeddedPdfRef = useRef(null);
  const scaleAmount = 0.5;
  const [emailInformation, setEmailInformation] = useState<any>(emailData);
  const [state, setState] = useState<ViewerState>({
    numPages: 0,
    pdfPath: null,
    templateLoaded: 0,
    pageNumber: 1,
    scale: 1.75,
    pdfLink: "#",
    showEmail: false,
    emailSent: false,
    showSignature: false,
  });

  /**
   *
   */
  useEffect(() => {
    if (url && url !== "") {
      const token = localStorage.getItem("access_token");
      fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((response): any => {
          const file = new File([response], title, { type: "application/pdf" });
          setState({
            ...state,
            pdfPath: file,
            templateLoaded: 1,
            pdfLink: URL.createObjectURL(file),
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (emailData) {
      const status = cacheContext.cache?.templatesStatus.find(
        (status: any) => status.nume === "GDPR"
      );
      getData(
        `${urlEnum.genericTemplates}/getTemplate/Email/${status?.id}`
      ).then((res) => {
        if (res.data.template)
          setEmailInformation({
            ...emailData,
            message: res.data.template.html,
            subject: res.data.template.subject,
          });
      });
    } else setEmailInformation(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData]);

  /**
   *
   * @param data
   */
  function onDocumentLoadSuccess(data: { numPages: any }) {
    const { numPages } = data;
    setState({ ...state, numPages });
  }

  /**
   * zoom in
   */
  function zoomIn() {
    setState({ ...state, scale: state.scale + scaleAmount });
  }

  /**
   * zoom out
   */
  function zoomOut() {
    setState({ ...state, scale: state.scale - scaleAmount });
  }

  /**
   * show email popup
   */
  function showEmail() {
    setState({ ...state, showEmail: !state.showEmail });
  }

  function showSignature() {
    setState({ ...state, showSignature: !state.showSignature });
  }

  /**
   * render pages
   */
  function renderPages() {
    const pages: Array<any> = [];
    for (let i = 0; i < state.numPages; i++) {
      pages.push(
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={classes.page}
          key={`page_${i + 1}`}
          scale={state.scale}
          pageNumber={i + 1}
        />
      );
    }
    return pages;
  }

  /**
   * print pdf
   */
  function printPdf() {
    if (embeddedPdfRef.current) {
      const embeddedPdf: any = embeddedPdfRef.current;
      embeddedPdf.contentWindow.print();
    }
  }

  /**
   * download pdf
   */
  const downloadPDF = () => {
    const token = localStorage.getItem("access_token");
    if (url)
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((response) => {
          download(response, title, "application/pdf");
        });
  };

  /**
   *
   * @param signature
   */
  function saveSignature(signature: any) {
    const formData = new FormData();
    formData.append("signature", signature.blobSignature);
    console.log("firmaId", firmaId);
    const url = `${urlEnum.clients}/signature/${id}/${doc}/${firmaId}`;

    postData(url, formData)
      .then((response) => {
        showSignature();
        const client: any =
          response.data && response.data.client ? response.data.client : null;

        if (resetClientFilePathForGDPR) resetClientFilePathForGDPR(client);
      })
      .catch((error) => {
        showSignature();
      });
  }
  return (
    <>
      <Dialog
        style={{ zIndex: 2000 }}
        maxWidth="xl"
        classes={{ paper: classes.overflowHidden }}
        open={open}
        onClose={() => onClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ display: "flex" }}>
          <div style={{ flexGrow: 2 }}>{title}</div>
          <p className={styles.close}>
            <CloseIcon onClick={onClose} className={styles.closeIcon} />
          </p>
          <div>{customTopMenu && customTopMenu}</div>
        </DialogTitle>
        <DialogContent
          ref={contentRef}
          style={{ background: "#eeeeee", overflowY: "scroll" }}
        >
          {state.pdfPath ? (
            <>
              {!state.templateLoaded ? (
                <div className={classes.loadingWrapper}>
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <PdfDocument
                  file={state.pdfPath}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {renderPages()}
                </PdfDocument>
              )}
            </>
          ) : (
            Vocabulary.loading
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.actionsWrapper }}>
        {signature ? (
          <IconButton
            style={{
              backgroundColor: "white",
              borderRadius: 7,
              color: "#ff6633",
            }}
            onClick={showSignature}
          >
            <GestureIcon />
            <Typography>{Vocabulary.sign}</Typography>
          </IconButton>):null}
          {isSign  ? (
            <IconButton
              style={{
                backgroundColor: "white",
                borderRadius: 7,
                color: "#ff6633",
              }}
              onClick={showEmail}
            >
              <EmailIcon />
              <Typography>{state.showEmail?Vocabulary.cancelMail:Vocabulary.sendMail}</Typography>
            </IconButton>
          ) : null}
          {state.pdfPath ? (
            <>
              <iframe
                style={{ display: "none" }}
                src={state.pdfLink}
                title="printPdf"
                ref={embeddedPdfRef}
              ></iframe>
              <IconButton onClick={printPdf}>
                <PrintIcon />
              </IconButton>
            </>
          ) : null}
          <IconButton onClick={downloadPDF}>
            <GetAppIcon />
          </IconButton>
          <IconButton disabled={state.scale <= 0.3} onClick={zoomOut}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton disabled={state.scale >= 2} onClick={zoomIn}>
            <ZoomInIcon />
          </IconButton>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onClose()}
          >
            {Vocabulary.close}
          </Button>
        </DialogActions>
        {state.showEmail ? (
  

            <EmailForm
              clientID={id || 0}
              doc={doc}
              firmaId={firmaId}
              to={emailInformation?.to || ""}
              subject={emailInformation?.subject || ""}
              message={emailInformation?.message || ""}
              onEmailSent={() =>
                setState({ ...state, showEmail: false, emailSent: true })
              }
            />
      
        ) : null}
      </Dialog>
      <SignatureModal
        openModalSignature={state.showSignature}
        showSignature={showSignature}
        saveSignature={(signature) => saveSignature(signature)}
      />
    </>
  );
}

export default PreviewPDF;
