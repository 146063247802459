/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { Button, Checkbox, Typography } from "@mui/material";
import { postData } from "../Services/postData";
import { urlEnum } from "../Utils/urlEnum";
import GenericModal from "../Modal/GenericModal";
import style from "../Styles/vehicleInfo.module.css";
import { VehicleContext } from "../Context/vehicleContext";
import { Vocabulary } from "../Utils/Vocabulary";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { isMobile } from "../Utils/utils";
import { ImageModel } from "../Models/Models";
import { multipleDelete } from "../Services/multipleDelete";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";
import PreviewPDF from "./Generic/PDFViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;
export default function StandardImageList() {
  const vehicleContext: any = useContext(VehicleContext);
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedIds, setDeletedIds] = useState<any>([]);
  const [selectedImages, setSelectedImages] = useState<any>(false);
  const [pressed, setPressed] = useState(true);
  const [onSiteStatus, setOnSiteStatus] = useState<any>(false);
  const [changedIds, setChangedIds] = useState<any>([]);
  const [selectedPDF, setSelectedPdf] = useState<any>();
  const [items, setItems]: any = useState(
    formatImagesDataForGrid(vehicleContext.state.images)
  );
  const height = window.screen.height;

  function onChange(
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) {
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }

  function formatImagesDataForGrid(images: any) {
    const formattedImages = Object.assign([], new ImageModel()); // clone the array

    formattedImages.forEach((item: any, index: any) => {
      item.sort_order = index;
    });
    return formattedImages;
  }

  useEffect(() => {
    setItems(vehicleContext?.state?.images);
  }, [vehicleContext?.state?.images]);

  /**
   *
   * @param files
   */
  function handleChangeDropFile(files: any) {
    const renamedFiles = files.map((file: any) => {
      const newFileName = file.name.replace(/\s+/g, "_");
      return new File([file], newFileName, { type: file.type });
    });

    if (renamedFiles.length !== 0) {
      setFiles(renamedFiles);
    }
  }
  /**
   *
   * @param status
   */
  function addButtonsForEachPhoto(status: string, index: any) {
    switch (status) {
      case "0":
        return addOnSite(index);
      case "1":
        return deleteFromSite(index);
      default:
        return addOnSite(index);
    }
  }
  /**
   *
   *
   */
  function deleteFromSite(index: any) {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            height: 500,
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            paddingTop: 7,
            paddingBottom: 7,
            cursor: "pointer",
            zIndex: 2000,
            color: "#fff",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              backgroundColor: "#f44",
              display: "flex",
              fontSize: 12,
              paddingTop: 7,
              paddingBottom: 7,
              justifyContent: "center",
              cursor: "pointer",
              zIndex: 1000,
              color: "#fff",
            }}
            onClick={() => hanndleDeleteFromSite(index)}
          >
            {Vocabulary.deleteFromSite}
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   *
   */
  function addOnSite(index: any) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          height: 500,
          display: "flex",
          justifyContent: "center",
          fontSize: 12,
          paddingTop: 7,
          paddingBottom: 7,
          cursor: "pointer",
          zIndex: 2000,
          color: "#fff",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,

            backgroundColor: "#0c7146",
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            paddingTop: 7,
            paddingBottom: 7,
            cursor: "pointer",
            zIndex: 1000,
            color: "#fff",
          }}
          onClick={() => handleAddOnSite(index)}
        >
          {Vocabulary.addOnSite}
        </div>
      </div>
    );
  }

  /**
   *
   *
   */
  function handleAddOnSite(index: any) {
    const localPhotos = Object.assign([], items);
    localPhotos[index].status_poza = "1";
    setItems(localPhotos);
    const localIdsArray = Object.assign([], changedIds);
    const localIds: any = {};
    localIds.id = localPhotos[index].id;
    localIds.status = "1";
    localIdsArray.push(localIds);
    setChangedIds(localIdsArray);
  }

  function handleAddAllOnSite() {
    const localPhotos = Object.assign([], items);
    localPhotos.forEach((item: any) => {
      item.status_poza = pressed ? "1" : "0";
    });
    setItems(localPhotos);
    const localIdsArray = Object.assign([], changedIds);
    localPhotos.forEach((item: any) => {
      const localIds: any = {};
      localIds.id = item.id;
      localIds.status = pressed ? "1" : "0";
      localIdsArray.push(localIds);
    });
    setChangedIds(localIdsArray);
    setPressed(!pressed);
  }
  /**
   *
   *
   */
  function hanndleDeleteFromSite(index: any) {
    const localPhotos = Object.assign([], items);
    localPhotos[index].status_poza = "0";
    setItems(localPhotos);
    const localIdsArray = Object.assign([], changedIds);
    const localIds: any = {};
    localIds.id = localPhotos[index].id;
    localIds.status = "0";
    localIdsArray.push(localIds);
    setChangedIds(localIdsArray);
  }

  /**
   *
   * @param index
   */
  function selectImage(index: number) {
    const newDeletedIds = deletedIds.slice();
    const idIndex = newDeletedIds.findIndex(
      (vehicle: any) => vehicle?.id == items[index].id
    );
    if (idIndex >= 0) {
      newDeletedIds.splice(idIndex, 1);
    } else {
      newDeletedIds.push(items[index]);
    }
    setDeletedIds(newDeletedIds);
  }

  /**
   *
   */
  function saveData() {
    if (files?.length > 0) {
      const formData = new FormData() as any;
      formData.append("vehicul_id", vehicleContext?.state?.vehicle?.id);
      formData.append("vin", vehicleContext?.state?.vehicle?.vin);
      formData.append("fileType", "Poze");
      if (files && files.length !== 0)
        files.forEach((file: any) => {
          formData.append(file.name, file);
        });
      else formData.append("files", []);
      postData(`${urlEnum.files}`, formData)
        .then((res) => {
          vehicleContext.updateVehicleImages(res.data.images);
          setOpenModal(false);
          setFiles([]);
        })
        .catch((err) => {
          setOpenModal(false);
        });
    }
  }

  /**
   *
   */
  function deleteImages() {
    const data = {
      data: { infoFiles: deletedIds },
    };
    //save the changes
    updateInBulk().then(() => {
      //delete the selected
      multipleDelete(`${urlEnum.multipleDeletePhotos}`, data)
        .then((res) => {
          vehicleContext.updateVehicleImages(res.data);
          setOpenDeleteModal(false);
        })
        .catch((err) => {
          setOpenDeleteModal(false);
        });
    });

    setSelectedImages(!selectedImages);
    setDeletedIds([]);
  }

  /**
   *
   */
  function updateInBulk() {
    const photoObject = Object.assign({}, new ImageModel()); // clone the array
    photoObject.vehicul_id = vehicleContext?.state?.vehicle?.id;
    photoObject.vin = vehicleContext?.state?.vehicle?.vin;
    items.forEach((item: any, index: any) => {
      photoObject?.infoFiles.push(item);
    });
    photoObject?.infoFiles?.forEach((item: any, index: any) => {
      item.sort_order = index;
    });
    return postData(`${urlEnum.multipleUpdate}`, photoObject)
      .then((res) => {
        vehicleContext.updateVehicleImages(res.data.images);
        setOpenModal(false);
        setSelectedImages(!selectedImages);
        setOnSiteStatus(false);
      })
      .catch((err) => {
        setOpenModal(false);
        setOnSiteStatus(false);
      });
  }

  function handleEditButton() {
    setSelectedImages(!selectedImages);
  }

  function handleCancelButton() {
    // resetContextState.resetState();
    setSelectedImages(!selectedImages);
    setItems(vehicleContext?.state?.images);
  }

  return (
    <div style={{ width: "100%" }}>
      <div className={style.buttonsContainer}>
        {selectedImages ? (
          <Button
            fullWidth
            variant="contained"
            className={style.buttons}
            onClick={() => setOnSiteStatus(true)}
            style={{ backgroundColor: "#FF6633", color: "white" }}
          >
            <Typography className={style.buttonText}>
              {Vocabulary.save}
            </Typography>
          </Button>
        ) : (
          <Button
            variant="contained"
            className={style.buttons}
            onClick={() => handleEditButton()}
          >
            <Typography className={style.buttonText}>
              {Vocabulary.edit}
            </Typography>
          </Button>
        )}

        {selectedImages ? (
          <Button
            variant="contained"
            className={style.buttons}
            onClick={() => setOpenDeleteModal(true)}
            disabled={deletedIds.length === 0 ? true : false}
            style={{ backgroundColor: "#F33" }}
          >
            <Typography className={style.buttonText}>
              {Vocabulary.deleteImages}
            </Typography>
          </Button>
        ) : null}
        {!selectedImages ? (
          <Button
            variant="contained"
            className={style.buttons}
            onClick={() => setOpenModal(true)}
          >
            <Typography className={style.buttonText}>
              {Vocabulary.addImages}
            </Typography>
          </Button>
        ) : null}
        {selectedImages ? (
          <>
            <Button
              variant="contained"
              onClick={() => handleAddAllOnSite()}
              style={{
                color: "white",
                backgroundColor: !pressed ? "#f01" : "#FF6633",
              }}
            >
              {pressed
                ? Vocabulary.setAllVisibleOnSite
                : Vocabulary.setAllHiddenOnSite}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              className={style.buttons}
              onClick={() => handleCancelButton()}
            >
              <Typography className={style.buttonText}>
                {Vocabulary.cancel}{" "}
              </Typography>
            </Button>
          </>
        ) : null}
      </div>

      <div style={{ display: "flex", marginTop: 50, overflow: "auto" }}>
        <div className={style.imageContainer}>
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={isMobile() ? 3 : 8}
              rowHeight={isMobile() ? height * 0.15 : height * 0.12}
              className={style.dropzone}
              // style={{
              //   height: isMobile() ? height * 0.6 : height * 0.5, // Adjust overall heigh
              //   overflowY: "auto", // Allow scrolling if content overflows
              //   minHeight: "300px",
              // }}
              disableDrag={!selectedImages}
            >
              {items?.map((image: any, index: number, key: number) => (
                <GridItem key={image?.id} className={style.gridItemContent}>
                  <div
                    // style={{
                    //   position: "relative",

                    //   height: "100%",
                    //   width: "auto",
                    //   background: `center/cover url(/api/${image.file}`,
                    //   backgroundColor: "#fff",
                    //   backgroundSize: "cover",
                    //   backgroundRepeat: "no-repeat",
                    //   backgroundPosition: "center center",
                    //   borderRadius: "7px",
                    //   minHeight: "100%",
                    // }}
                    className={style.image}
                    key={image?.id}
                  >
                    <div
                      style={{
                        // position: "relative",
                        backgroundColor: "#f4f",
                        zIndex: 20,
                      }}
                    >
                      {image.name.includes(".pdf") ? (
                        <PdfDocument
                          file={`/api/${image.file}`}
                          onClick={() => setSelectedPdf(image)}
                        >
                          <Page
                            pageNumber={1}
                            //className={style.img}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        </PdfDocument>
                      ) : (
                        <img
                          src={`/api/${image.file}`}
                          srcSet={`/api/${image.file}`}
                          className={style.img}
                          data-file={`${urlEnum.vehicles}/${image.file}`}
                          alt={image.name}
                          loading="lazy"
                        />
                      )}
                    </div>

                    {selectedImages ? (
                      <Checkbox
                        className={style.checkbox}
                        onClick={() => selectImage(index)}
                        checked={deletedIds.find(
                          (id: number) => id === image.id
                        )}
                      ></Checkbox>
                    ) : null}

                    {image.status_poza === "0" ? null : (
                      <div
                        style={{
                          position: "absolute",
                          borderRadius: "0px 0px 0px 7px",
                          right: 0,
                          backgroundColor: "#0c7146",
                          display: "flex",
                          fontSize: 12,

                          justifyContent: "center",
                          padding: 7,
                          zIndex: 1000,
                          color: "#fff",
                        }}
                      >
                        {Vocabulary.visibleOnSite}
                      </div>
                    )}
                    {selectedImages
                      ? addButtonsForEachPhoto(image.status_poza, index)
                      : null}

                    {/* add new images */}
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
            <PreviewPDF
              title={selectedPDF?.name || ""}
              open={selectedPDF ? true : false}
              url={`/api/${selectedPDF?.file}`}
              onClose={() => setSelectedPdf(null)}
            />
          </GridContextProvider>
        </div>

        {/* add new images */}
        <GenericModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={Vocabulary.addNewImages}
          maxWidth={"80%"}
        >
          <DropzoneArea
            acceptedFiles={["image/*,application/*"]}
            dropzoneClass={style.dropzoneArea}
            maxFileSize={640000000} // 640 MB
            filesLimit={40}
            onChange={handleChangeDropFile}
            onDelete={() => setFiles([])}
            showAlerts={false}
          />
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={saveData}
              style={{ color: "white", marginTop: 30 }}
            >
              {Vocabulary.save}
            </Button>
          </div>
        </GenericModal>
        {/* Edit images status on site */}
        <GenericModal
          open={onSiteStatus}
          onClose={() => setOnSiteStatus(false)}
          title={Vocabulary.confirmEditPhotosStatus}
        >
          <Typography style={{ margin: 10, textAlign: "center" }}>
            {Vocabulary.confirmEditPhotos}
          </Typography>
          <Typography style={{ margin: 10, textAlign: "center" }}>
            {Vocabulary.confirmEditPhotosStatusMessage}
          </Typography>

          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOnSiteStatus(false)}
              style={{ color: "white" }}
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              variant="contained"
              onClick={() => updateInBulk()}
              style={{ color: "white" }}
            >
              {Vocabulary.save}
            </Button>
          </div>
        </GenericModal>
        {/* delete images */}
        <GenericModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title=""
        >
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: 20 }}>{Vocabulary.confirmDeleteImages}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                style={{ margin: 10 }}
                onClick={() => setOpenDeleteModal(false)}
              >
                {Vocabulary.no}
              </Button>
              <Button
                onClick={deleteImages}
                fullWidth
                variant="contained"
                style={{ color: "white", margin: 10 }}
              >
                {Vocabulary.yes}
              </Button>
            </div>
          </div>
        </GenericModal>
      </div>
    </div>
  );
}
