import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import { euCountries } from "../../../../Utils/Constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { anafFormatDate } from "../../../../Utils/formatDate";
import styles from "../../../../Styles/progressAction.module.css";
import moment from "moment";

export default function PartenerComercial(props: any) {
  const { control, taraOrigine, dataIntrarePrinPTF } = props;

  const getDefaultValueForDataIntrarePrinPTF = () => {
    if (dataIntrarePrinPTF?.dataTransport) {
      const defaultValue = moment(dataIntrarePrinPTF.dataTransport);
      return defaultValue;
    }
  };

  const isWeekend = (date: moment.Moment) => {
    const day = date.day();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
  };

  const getNextWorkingDays = (daysToAdd:number) => {
    const workingDays = [];
    let currentDate = moment();
  
    while (workingDays.length < daysToAdd) {
      currentDate = currentDate.add(1, 'day');
      if (!isWeekend(currentDate)) {
        workingDays.push(currentDate.clone());
      }
    }
  
    return workingDays;
  };

  const shouldDisableDate = (date: moment.Moment) => {
    return !getNextWorkingDays(3).some((d) => d.isSame(date, 'day'));
  };

  return (
    <fieldset
      style={{
        border: "1px solid #0000001f",
        borderRadius: 10,
        marginTop: 10,
      }}
    >
      <legend
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0rem 0.8rem",
        }}
      >
        {Vocabulary.partenerComercial}
      </legend>
      <Grid container spacing={isMobile() ? 1 : 3} style={{ marginTop: 5 }}>
        <Grid item md={3} xs={12}>
          <Controller
            name="partenerComercial.codTara"
            control={control}
            defaultValue={
              euCountries?.find((tara) => tara.name === taraOrigine)?.code
            }
            render={({
              field: { ref, value, onChange, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => {
                  return option.name;
                }}
                id="bunuriTransportate.codTara"
                // onChange={(event, value: any) => field.onChange(value.code)}
                onChange={(event, value: any) => {
                  onChange(value?.code || ""); // Update form value to the selected option's cod
                }}
                value={
                  euCountries?.find((option: any) => option.code === value) || {
                    name: "Austria",
                    code: "AT",
                  }
                }
                options={euCountries}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.statMembruUE}
                    name="bunuriTransportate.codTara"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="partenerComercial.codOrgTransport"
            defaultValue={undefined}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id="partenerComercial.codOrgTransport"
                label={Vocabulary.codPartener}
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: !!value }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="partenerComercial.denumire"
            defaultValue={undefined}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value || ""} // Ensure value is a string
                error={!!error}
                helperText={error?.message}
                id="partenerComercial.denumire"
                label={Vocabulary.denumirePartenerUE}
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: !!value }} // Force the label to shrink when there is a value
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="dateTransport.dataTransport"
            control={control}
            defaultValue={getDefaultValueForDataIntrarePrinPTF()}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { error },
            }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className={styles.datePTF}
                  format={anafFormatDate}
                  onChange={(date) => {
                    onChange(date);
                  }}
                  shouldDisableDate={shouldDisableDate} 
                  value={moment(value)}
                  defaultValue={getDefaultValueForDataIntrarePrinPTF()}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                  label={Vocabulary.dateDeIntrarePrinPTF}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
}
