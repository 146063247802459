import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller } from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import {
  scopOperatiune,
  scopOperatiuni,
} from "../../../../Utils/Enums/ScopOperatiune";
import { EuropeanNumberFormatCustom } from "../../../../Utils/NumericFormatComponent";


export default function BunuriTransport(props: any) {
  const { control, bunuriTransportate, unitateMasura, pretAchizitie, denumireBunTransportat } = props;
  return (
    <fieldset
      style={{
        border: "1px solid #0000001f",
        borderRadius: 10,
        marginTop: 10,
      }}
    >
      <legend
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0rem 0.8rem",
        }}
      >
        {Vocabulary.bunuriTransport}
      </legend>
      <Grid container spacing={isMobile() ? 1 : 3} style={{ marginTop: 5 }}>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.codScopOperatiune"
            control={control}
            defaultValue={scopOperatiuni[0].cod}
            render={({
              field: { ref, value, onChange, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: scopOperatiune) => option.denumire}
                id="bunuriTransportate.0.codScopOperatiune"
                options={scopOperatiuni}
                onChange={(event, value: scopOperatiune) => {
                  onChange(value?.cod || ""); // Update form value to the selected option's cod
                }}
                value={
                  scopOperatiuni?.find((option) => option.cod === value) ||
                  undefined
                }
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.codScopOperatiune}
                    name="bunuriTransportate.0.codScopOperatiune"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.codTarifar"
            control={control}
            defaultValue={bunuriTransportate[42].codTarifar}
            render={({ field: { ref, onChange, value, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => option.denumire}
                id="bunuriTransportate.0.codTarifar"
                // onChange={(event, value: any) =>
                //   field.onChange(value.codTarifar)
                // }
                onChange={(event, value: any) => {
                  onChange(value?.codTarifar || "");
                }}
                value={
                  bunuriTransportate?.find((option: any) => option.codTarifar === value) ||
                  undefined
                }
                options={bunuriTransportate}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.categorieBunuri}
                    name="bunuriTransportate.0.codTarifar"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <Controller
            name="bunuriTransportate.0.denumireMarfa"
            defaultValue={`Autoturism Second-Hand ${denumireBunTransportat}`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id="bunuriTransportate.0.denumireMarfa"
                label={Vocabulary.denumireBunuri}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={1} xs={12}>
          <Controller
            name="bunuriTransportate.0.cantitate"
            defaultValue={1}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                type="number"
                error={!!error}
                helperText={error?.message}
                id="bunuriTransportate.0.cantitate"
                label={Vocabulary.cantitate}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.codUnitateMasura"
            control={control}
            defaultValue={unitateMasura[0].unitate}
            render={({ field: { ref, onChange, value, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                {...field}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                id="codUnitateMasura"
                // onChange={(event, value: any) => field.onChange(value.unitate)}
                options={unitateMasura}
                onChange={(event, value: any) => {
                  onChange(value?.unitate || ""); // Update form value to the selected option's cod
                }}
                value={
                  unitateMasura?.find((option: any) => option.unitate === value) ||
                  undefined
                }
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.unitateMasura}
                    name="codUnitateMasura"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.greutateNeta"
            defaultValue={undefined}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                value={value}
                type="number"
                error={!!error}
                helperText={error?.message}
                id="bunuriTransportate.0.greutateNeta"
                label={Vocabulary.greutateNeta}
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: !!value }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.greutateBruta"
            defaultValue={undefined}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                type="number"
                value={value}
                error={!!error}
                helperText={error?.message}
                id="bunuriTransportate.0.greutateBruta"
                label={Vocabulary.greutateBruta}
                size="small"
                variant="outlined"
                InputLabelProps={{ shrink: !!value }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="bunuriTransportate.0.valoareLeiFaraTva"
            defaultValue={pretAchizitie}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                fullWidth
                style={{
                  marginBottom: 15,
                }}
                InputProps={{
                  inputComponent: EuropeanNumberFormatCustom,
                }}
                value={value}
                error={!!error}
                helperText={error?.message}
                id="bunuriTransportate.0.valoareLeiFaraTva"
                label={Vocabulary.valoareLeiFaraTva}
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
}
