/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useContext, useState } from "react";
import {
  Button,
  Chip,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete, Edit } from "@mui/icons-material";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GenericModal from "../../Modal/GenericModal";
import User from "./User";
import { CacheContext } from "../../Context/cacheContext";
import { deleteData } from "../../Services/deleteData";
import { State } from "./UsersDashboard";
import { localUrlEnum, urlEnum } from "../../Utils/urlEnum";
import { useNavigate } from "react-router-dom";
import { updateData } from "../../Services/updateData";
import * as React from "react";
import { exportTable, isMobile } from "../../Utils/utils";
import { tableLayoutOnMobile } from "../../Utils/Constants";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

type UsersTableProps = {
  state: State;
  openNewOption?: any;
  handleOpenModalWindow?: any;
  setEditedUser?: any;
  editedUser?: any;
  getUsers: (
    isActive: boolean,
    page: number,
    perPage: number,
    search?: string | null
  ) => void;
};
export default function UsersTable(props: UsersTableProps) {
  const {
    setEditedUser,
    editedUser,
    state,
    getUsers,
    openNewOption,
    handleOpenModalWindow,
  } = props;
  const navigate = useNavigate();
  const cacheContext = useContext(CacheContext) as any;
  const [inactiveUserId, setInactiveUserId] = useState<number | null>(null);
  const [activeUserId, setActiveUserId] = useState<number | null>(null);
  const [openInactiveModal, setOpenInactiveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState<number | null>(null);
  const userId: any = localStorage.getItem("useId");

  /**
   *
   */
  const usersHeader = [
    {
      label: `${Vocabulary.userName}`,
      name: "name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: `${Vocabulary.phone}`,
      name: "telefon",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.email}`,
      name: "email",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.roles,
      name: "roles",
      options: {
        filter: false,
        sort: false,
        display: false,
        downloadType: "array",
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          return (
            <>
              {dataIndex.map((role: any) => {
                return <Chip size="small" label={role.name} />;
              })}
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.administrativeLocation}`,
      name: "locatie_administrativa_id",
      options: {
        sort: false,
        download: false,
        customBodyRender: (locatie_administrativa_id: number) => {
          const location = cacheContext?.cache?.locations?.find(
            (location: any) => location.id === locatie_administrativa_id
          );
          return <Typography>{location?.nume}</Typography>;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "",
      options: {
        viewColumns: false,
        download: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10, float: "right" }}>
              {state.isActive ? (
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  {state.users[rowIndex].id !== parseInt(userId) ? (
                    <Tooltip title={Vocabulary.inactivateUser}>
                      <ToggleButton
                        onClick={() => {
                          setInactiveUserId(state.users[rowIndex].id as number);
                          setOpenInactiveModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <PersonOffIcon />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                  <Tooltip title={Vocabulary.edit}>
                    <ToggleButton
                      onClick={() => {
                        setEditedUser(rowIndex);
                        handleOpenModalWindow(true);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <Edit />
                    </ToggleButton>
                  </Tooltip>
                  {state.users[rowIndex].id !== parseInt(userId) ? (
                    <Tooltip title={Vocabulary.delete}>
                      <ToggleButton
                        onClick={() => {
                          setDeletedId(state.users[rowIndex].id as number);
                          setOpenDeleteModal(true);
                        }}
                        value="center"
                        aria-label="centered"
                      >
                        <Delete />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup exclusive aria-label="text alignment">
                  <Tooltip title={Vocabulary.activateUser}>
                    <ToggleButton
                      onClick={() => {
                        setActiveUserId(state.users[rowIndex].id as number);
                        setOpenInactiveModal(true);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <PersonIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title={Vocabulary.visibility}>
                    <ToggleButton
                      onClick={() => {
                        handleOpenModalWindow(true);
                        setEditedUser(rowIndex);
                      }}
                      value="center"
                      aria-label="centered"
                    >
                      <VisibilityIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              )}
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
    return {
      responsive: tableLayoutOnMobile,
      selectableRows: "none" as any,
      viewColumns: true,
      rowsPerPageOptions: [
        10,
        50,
        100,
        state?.usersNumber > 100 ? state?.usersNumber : 1000,
      ],
      confirmFilters: true,
      filter: false,
      print: false,
      download: true,
      fixedHeader: true,
      count: state.usersNumber,
      rowsPerPage: state.perPage,
      page: state.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        viewColumns: {
          title: Vocabulary.viewColumns,
        },
        toolbar: {
          downloadCsv: Vocabulary.downloadExcel,
          viewColumns: Vocabulary.selectColumns,
        },
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: false,
        },
        filename: Vocabulary.usersName?.toLowerCase() + ".xlsx",
      },
      onChangePage: (page: number) => {
        if (state.search)
          navigate(
            `${localUrlEnum.users}?isActive=${state.isActive}page=${
              page + 1
            }?perPage=${state.perPage}?search=${state.search}`
          );
        else
          navigate(
            `${localUrlEnum.users}?isActive=${state.isActive}?page=${
              page + 1
            }?perPage=${state.perPage}`
          );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (state.search)
          navigate(
            `${localUrlEnum.users}?isActive=${
              state.isActive
            }?page=${1}?perPage=${numberOfRows}?search=${state.search}`
          );
        else
          navigate(
            `${localUrlEnum.users}?isActive=${
              state.isActive
            }?page=${1}?perPage=${numberOfRows}`
          );
        window.scrollTo(0, 0);
      },
      onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
        const alteredData = data.map((column: any, index: number) => {
          column.data = column?.data?.map((value: any, key: number) => {
            if (columns[key].downloadType) {
              switch (columns[key].downloadType) {
                case "array":
                  {
                    let result = "";
                    value.forEach((obj: any, key: number) => {
                      result += key ? ", " + obj.name : obj.name;
                    });
                    value = result;
                  }
                  break;
                case "boolean": {
                  value = cacheContext?.cache?.locations?.find(
                    (location: any) => location.id === value
                  )?.nume;
                  break;
                }
              }
            } else if (typeof value == "object") {
              if (value) {
                const name = Object.values(value)[1];
                value = name;
              }
            }

            return value;
          });

          return column;
        });
        return `${buildHead(columns)}${buildBody(alteredData)}`;
      },
    };
  }

  /**
   * Close delete modal
   */
  function onCloseDeleteModal() {
    setOpenDeleteModal(false);
    setDeletedId(null);
  }

  /**
   * Close inactive modal
   */
  function onCloseInactiveModal() {
    setOpenInactiveModal(false);
    setActiveUserId(null);
    setInactiveUserId(null);
  }
  /**
   * Inactivate users
   * @param id
   *  */
  function inactivateUser() {
    let url = "";
    if (activeUserId)
      url = `${urlEnum.users}/inactivateUser/${activeUserId}/true`;
    else url = `${urlEnum.users}/inactivateUser/${inactiveUserId}/false`;

    updateData(url)
      .then((res) => {
        cacheContext.setCache({
          ...cacheContext.cache,
          users: res.data.users,
        });
        onCloseInactiveModal();
        getUsers(state.isActive, state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseInactiveModal();
      });
  }
  /**
   * Delete users
   */
  function deleteUser() {
    deleteData(`${urlEnum.users}/${deletedId}`)
      .then((res) => {
        cacheContext.setCache({
          ...cacheContext.cache,
          users: res.data.users,
        });
        onCloseDeleteModal();
        getUsers(state.isActive, state.page, state.perPage, state.search);
      })
      .catch((err) => {
        onCloseDeleteModal();
      });
  }

  /**
   *
   */
  const exportData = (props: any) => {
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const fileName = Vocabulary.usersName?.toLowerCase() + ".xlsx";
    const url = `${urlEnum.users}/export/table/data`;

    exportTable(url, state, fileName, exportedColumns);
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar {...props}>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <div>
      <MUIDataTable
        title={""}
        data={state.users}
        columns={usersHeader}
        options={getTableOptions()}
        components={{
          TableToolbar: CustomToolbar,
        }}
      />
      <User
        open={openNewOption}
        onClose={(shouldRefetch) => {
          handleOpenModalWindow(false);
          setEditedUser(null);
          if (shouldRefetch) {
            getUsers(state.isActive, state.page, state.perPage, state.search);
          }
        }}
        title={editedUser ? Vocabulary.editUser : Vocabulary.addNewUser}
        editedUser={editedUser}
        visibilityUser={editedUser ? !state.isActive : false}
      />

      <GenericModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={Vocabulary.deleteUser}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.deleteMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCloseDeleteModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={deleteUser}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <GenericModal
        open={openInactiveModal}
        onClose={onCloseInactiveModal}
        title={
          activeUserId ? Vocabulary.activateUser : Vocabulary.inactivateUser
        }
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {activeUserId
            ? Vocabulary.activateUserMessage
            : Vocabulary.inactivateUserMessage}
        </p>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={onCloseInactiveModal}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={inactivateUser}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </div>
  );
}
