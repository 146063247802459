import {
  Grid,
  TableCell,
  TableFooter,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import style from "../../Styles/salaries.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import MUIDataTable from "mui-datatables";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import moment from "moment";
import { europeanDate, formatNumber } from "../../Utils/formatDate";
import { urlEnum } from "../../Utils/urlEnum";
import { postData } from "../../Services/postData";
import { NumericFormatCustom } from "../../Utils/NumericFormatComponent";
import { KeyboardEvent, useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { RolesEnum } from "../../Utils/Constants";
import { VehicleContext } from "../../Context/vehicleContext";
import { useLocation, useNavigate } from "react-router-dom";

export type VehiclesTableProps = {
  user: any;
  rows: any[];
  state: any;
  numberFormat: Intl.NumberFormat;
  getVehicles(
    vehiclesSoldPage?: number,
    vehiclesSoldPerPage?: number,
    otherVehiclesPage?: number,
    otherVehiclesPerPage?: number
  ): Promise<void>;
  month: number | string;
  year: number | string;
  setProfitFromOtherUsers(profitFromOhterUsers: any): void;
  trimesterData: any;
};

export default function VehiclesTable(props: VehiclesTableProps) {
  const {
    user,
    rows,
    //trimesterSettings,
    trimesterData,
    //currentTrimester,
    state,
    numberFormat,
    getVehicles,
    month,
    year,
    setProfitFromOtherUsers,
  } = props;
  const [managedUsersVehicles, setManagedUsersVehicles] = useState({
    vehicles: [],
    vehiclesNumber: 0,
    totalIncome: 0,
    page: 0,
    perPage: 15,
  });

  const [userIntermediatedVehicles, setUserIntermediatedVehicles] = useState({
    vehicles: [],
    vehiclesNumber: 0,
    totalCommission: 0,
    page: 0,
    perPage: 15,
  });
  const roles = localStorage.getItem("roles") || "";
  const isAdmin = roles.includes(RolesEnum?.admin);
  const [editMode, setEditMode] = useState(false);
  const vehicleContext: any = useContext(VehicleContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getManagedUsersVehicles();
    getUserIntermediatedVehicles();
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   */
  const getManagedUsersVehicles = async (
    page = managedUsersVehicles.page,
    perPage = managedUsersVehicles.perPage
  ) => {
    const url = `${urlEnum.vehicleCost}/managedUserVehicles/${user?.id}/${month}/${year}/${page}/${perPage}`;
    const response = await getData(url);
    if (response?.data)
      setManagedUsersVehicles({
        ...managedUsersVehicles,
        vehicles: response?.data?.vehicles ?? [],
        vehiclesNumber: response?.data?.vehiclesNumber,
        page: page,
        perPage: perPage,
        totalIncome: response?.data?.totalIncome,
      });
    setProfitFromOtherUsers(response?.data?.totalCommission);
  };

  /**
   *
   * @param page
   * @param perPage
   */
  const getUserIntermediatedVehicles = async (
    page = userIntermediatedVehicles.page,
    perPage = userIntermediatedVehicles.perPage
  ) => {
    const url = `${urlEnum.vehicleCost}/userIntermediatedVehicles/${user?.id}/${month}/${year}/${page}/${perPage}`;
    const response = await getData(url);
    if (response?.data)
      setUserIntermediatedVehicles({
        ...userIntermediatedVehicles,
        vehicles: response?.data?.vehicles,
        vehiclesNumber: response?.data?.vehiclesNumber,
        page: page,
        perPage: perPage,
        totalCommission: response?.data?.totalCommission,
      });
  };

  /**
   *
   * @param vehicleId
   * @param newValue
   */
  const saveTotalCommissionForVehicle = async (
    vehicleId: number,
    newValue: string,
    type: string
  ) => {
    const url = urlEnum.vehicleCost;
    const response = await postData(url, { vehicleId, newValue, type });
    if (response?.data?.vehicleCosts) getVehicles();
  };

  // Handle Enter key press
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | undefined) => {
    event?.stopPropagation();
    if (event?.key === "Enter") {
      setEditMode(false);
    }
  };

  const handleChangeEditMode = (event: any) => {
    event.stopPropagation();
    setEditMode(true);
  };
  /**
   *
   */
  const columnsSold = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pret_vanzare_ron",
      label: Vocabulary.salePrice,
      options: {
        customBodyRender: (number: any) => (
          <Typography>{formatNumber(number)} Lei</Typography>
        ),
      },
    },
    {
      name: "data_vanzare",
      label: Vocabulary.sellDate,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return <Typography>{d.local().format(europeanDate)}</Typography>;
        },
      },
    },
    {
      name: "profit_brut",
      label: Vocabulary.grossProfit,
      options: {
        customBodyRender: (number: any) => (
          <Typography>{formatNumber(parseFloat(number))} Lei</Typography>
        ),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.nume} </Typography>
        ),
      },
    },
    {
      name: "comision_vanzare",
      label: Vocabulary.comisionVanzare,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "C_Volum",
      label: Vocabulary.targetCommision,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "C_CA",
      label: Vocabulary.CACommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "C_Profit",
      label: Vocabulary.profitCommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "bonus_vanzare_vehicul",
      label: Vocabulary.vehicleSellingBonus,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "initial_total_comisioane",
      label: Vocabulary.initialTotalCommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "total_comisioane",
      label: Vocabulary.totalCommission,
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {editMode ? (
                <TextField
                  value={value}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(e) => {
                    const vehicleId =
                      state?.vehiclesSold[tableMeta.rowIndex]?.vehicul_id;
                    saveTotalCommissionForVehicle(
                      vehicleId,
                      e.target?.value,
                      "seller"
                    );
                  }}
                  onBlur={() => setEditMode(false)}
                  onKeyDown={handleKeyDown} // Handle Enter key press
                />
              ) : (
                <Typography>{formatNumber(parseFloat(value))} Lei </Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isAdmin && (
                <ModeEditIcon
                  className={style.icon}
                  onClick={handleChangeEditMode}
                />
              )}
            </>
          );
        },
      },
    },
  ];

  /**
   *
   */
  const columns = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pret_vanzare_ron",
      label: Vocabulary.salePrice,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "data_vanzare",
      label: Vocabulary.sellDate,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return <Typography>{d.local().format(europeanDate)}</Typography>;
        },
      },
    },
    {
      name: "profit_brut",
      label: Vocabulary.grossProfit,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.nume} </Typography>
        ),
      },
    },

    {
      name: "user_achizitie",
      label: Vocabulary.purchaseUser,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.name} </Typography>
        ),
      },
    },
    {
      name: "user_logistica",
      label: Vocabulary.logisticUser,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.name} </Typography>
        ),
      },
    },

    {
      name: "comision_achizitie",
      label: Vocabulary.comisionAchizitie,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "comision_logistica",
      label: Vocabulary.comisionLogistica,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "initial_total_comisioane",
      label: Vocabulary.initialTotalCommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei </Typography>
        ),
      },
    },
    {
      name: "total_comisioane",
      label: Vocabulary.totalCommission,
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {editMode ? (
                <TextField
                  value={value}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  //type="number"
                  //onChange={(e) =>  state.vehiclesSold[tableMeta.rowIndex].total_comisioane=e.target.value}
                  onChange={(e) => {
                    const vehicleId =
                      state?.otherVehicles[tableMeta.rowIndex]?.vehicul_id;
                    saveTotalCommissionForVehicle(
                      vehicleId,
                      e.target?.value,
                      "logistics"
                    );
                  }}
                  onBlur={() => setEditMode(false)}
                  onKeyDown={handleKeyDown} // Handle Enter key press
                />
              ) : (
                <Typography>{formatNumber(parseFloat(value))} Lei </Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isAdmin && (
                <ModeEditIcon
                  className={style.icon}
                  onClick={handleChangeEditMode}
                />
              )}
            </>
          );
        },
      },
    },
  ];

  /**
   *
   */
  const managedUsersVehiclesTableColumns = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pret_vanzare_ron",
      label: Vocabulary.salePrice,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "data_vanzare",
      label: Vocabulary.sellDate,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return <Typography>{d.local().format(europeanDate)}</Typography>;
        },
      },
    },
    {
      name: "profit_brut",
      label: Vocabulary.grossProfit,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.nume} </Typography>
        ),
      },
    },

    {
      name: "user_vanzator",
      label: Vocabulary.seller,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.name} </Typography>
        ),
      },
    },
    {
      name: "comision_manager",
      label: Vocabulary.managerCommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
  ];

  /**
   *
   */
  const userIntermediatedVehiclesTableColumns = [
    {
      name: "nume_complet",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "vin",
      label: Vocabulary.vin,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pret_vanzare_ron",
      label: Vocabulary.salePrice,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "data_vanzare",
      label: Vocabulary.sellDate,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return <Typography>{d.local().format(europeanDate)}</Typography>;
        },
      },
    },
    {
      name: "profit_brut",
      label: Vocabulary.grossProfit,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
    {
      name: "tip_achizitie_r",
      label: Vocabulary.purchaseType,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{object?.nume} </Typography>
        ),
      },
    },
    {
      name: "comision_intermediere",
      label: Vocabulary.intermediatedVehiclesCommission,
      options: {
        customBodyRender: (object: any) => (
          <Typography>{formatNumber(parseFloat(object))} Lei</Typography>
        ),
      },
    },
  ];

  /**
   *
   */
  const optionsSold = {
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    rowsPerPageOptions: [
      10, 50, 100,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: state.vehiclesSoldPage,
    serverSide: true,
    rowsPerPage: state.vehiclesSoldPerPage,
    search: false,
    count: state?.targetVolum,
    rowHover: true,
    onChangePage: (page: number) => {
      getVehicles(
        page,
        state.vehiclesSoldPerPage,
        state.otherVehiclesPage,
        state.vehiclesSoldPerPage
      );
      window.scrollTo(0, 0);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getVehicles(
        0,
        numberOfRows,
        state.otherVehiclesPage,
        state.vehiclesSoldPerPage
      );
      window.scrollTo(0, 0);
    },
    onRowClick: (rowData: any, rowState: any) => {
      if (!editMode) {
        const vehicleId = state?.vehiclesSold[rowState.rowIndex]?.vehicul_id;
        vehicleContext.updateVehicle({});
        navigate(`/vehiclesDashboard/vehicleDashboard/${vehicleId}`);
      }
    },
  };

  /**
   *
   */
  const options = {
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    rowsPerPageOptions: [
      10, 50, 100,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: state.otherVehiclesPage,
    serverSide: true,
    rowsPerPage: state.otherVehiclesPerPage,
    search: false,
    count: state.otherVehiclesNumber,
    rowHover: true,
    onChangePage: (page: number) => {
      getVehicles(
        state.vehiclesSoldPage,
        state.vehiclesSoldPerPage,
        page,
        state.otherVehiclesPerPage
      );
      window.scrollTo(0, 0);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getVehicles(
        state.vehiclesSoldPage,
        state.vehiclesSoldPerPage,
        0,
        numberOfRows
      );
      window.scrollTo(0, 0);
    },
    onRowClick: (rowData: any, rowState: any) => {
      if (!editMode) {
        const vehicleId = state?.otherVehicles[rowState.rowIndex]?.vehicul_id;
        vehicleContext.updateVehicle({});
        navigate(`/vehiclesDashboard/vehicleDashboard/${vehicleId}`);
      }
    },
  };

  /**
   *
   */
  const managedUsersVehiclesTableOptions = {
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    rowsPerPageOptions: [
      15, 50, 100,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: managedUsersVehicles.page,
    serverSide: true,
    rowsPerPage: managedUsersVehicles.perPage,
    search: false,
    count: managedUsersVehicles.vehiclesNumber,
    rowHover: true,
    onChangePage: (page: number) => {
      getManagedUsersVehicles(page, managedUsersVehicles.perPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getManagedUsersVehicles(0, numberOfRows);
    },
    customTableBodyFooterRender: () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell colSpan={9}>
              <Typography>
                {`${Vocabulary.totalProfit}:    ${formatNumber(
                  managedUsersVehicles?.totalIncome
                )} Lei`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    },
    onRowClick: (rowData: any, rowState: any) => {
      if (!editMode) {
        const vehicleId = Object.assign(
          managedUsersVehicles.vehicles[rowState.rowIndex]
        )?.vehicul_id;
        vehicleContext.updateVehicle({});
        navigate(`/vehiclesDashboard/vehicleDashboard/${vehicleId}`);
      }
    },
  };

  /**
   *
   */
  const userIntermediatedVehiclesTableOptions = {
    filter: false,
    download: false,
    viewColumns: false,
    responsive: "standard" as any,
    rowsPerPageOptions: [
      15, 50, 100,
      //state?.vehiclesNumber > 100 ? state?.vehiclesNumber : 1000,
    ],
    sort: false,
    textLabels: {
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
      pagination: Vocabulary.pagination,
    },
    fixedHeader: false,
    fixedSelectColumn: false,
    selectableRows: "none" as any,
    print: false,
    page: userIntermediatedVehicles.page,
    serverSide: true,
    rowsPerPage: userIntermediatedVehicles.perPage,
    search: false,
    count: userIntermediatedVehicles.vehiclesNumber,
    rowHover: true,
    onChangePage: (page: number) => {
      getManagedUsersVehicles(page, userIntermediatedVehicles.perPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      getManagedUsersVehicles(0, numberOfRows);
    },
    onRowClick: (rowData: any, rowState: any) => {
      if (!editMode) {
        const vehicleId = Object.assign(
          userIntermediatedVehicles.vehicles[rowState.rowIndex]
        ).vehicul_id;
        vehicleContext.updateVehicle({});
        navigate(`/vehiclesDashboard/vehicleDashboard/${vehicleId}`);
      }
    },
  };

  return (
    <>
      {state?.targetVolum != 0 &&
        rows?.map((row, index) => {
          if (row.key in Object.assign(state)) {
            const target = trimesterData[row.key];
            const userSales = Object.assign(state)[row.key];

            return (
              <Grid item xs={4} key={index}>
                <Typography className={style.heading}>{row.label}</Typography>
                <Gauge
                  value={Number(userSales)}
                  valueMax={Number(target)}
                  height={100}
                  startAngle={-110}
                  endAngle={110}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 20,
                      display: "none",
                    },
                  }}
                />
                <Typography
                  style={{
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {`${numberFormat.format(userSales)} / ${numberFormat.format(
                    target
                  )} ${index ? "Lei" : "masini"}`}
                </Typography>
              </Grid>
            );
          }
        })}
      {state?.vehiclesSold?.length ? (
        <Grid item xs={12} sm={12}>
          <div style={{ width: "100%" }}>
            <MUIDataTable
              title={Vocabulary.selledCars}
              data={state.vehiclesSold}
              columns={columnsSold}
              options={optionsSold}
            />
          </div>
        </Grid>
      ) : null}

      {state.otherVehicles?.length ? (
        <Grid item xs={12} sm={12}>
          <MUIDataTable
            title={`${Vocabulary.logisticsName} / ${Vocabulary.acquisitionsName}`}
            data={state.otherVehicles}
            columns={columns}
            options={options}
          />
        </Grid>
      ) : null}
      {managedUsersVehicles?.vehicles.length != 0 && (
        <Grid item xs={12} sm={12}>
          <MUIDataTable
            title={Vocabulary.vehiclesSoldByManagedUsers}
            data={managedUsersVehicles?.vehicles}
            columns={managedUsersVehiclesTableColumns}
            options={managedUsersVehiclesTableOptions}
          />
        </Grid>
      )}
      {userIntermediatedVehicles?.vehicles.length != 0 && (
        <Grid item xs={12} sm={12}>
          <MUIDataTable
            title={Vocabulary.intermediatedVehicles}
            data={userIntermediatedVehicles?.vehicles}
            columns={userIntermediatedVehiclesTableColumns}
            options={userIntermediatedVehiclesTableOptions}
          />
        </Grid>
      )}
    </>
  );
}
