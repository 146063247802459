/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton } from "@mui/material";
import { FiltersProps, StateProps } from "./Reports";
import { localUrlEnum } from "../../Utils/urlEnum";
import { formatNumber } from "../../Utils/formatDate";
import { VehicleContext } from "../../Context/vehicleContext";
import { useContext } from "react";
import { tableLayoutOnMobile } from "../../Utils/Constants";

type StatisticallyProps = {
  filters: FiltersProps;
  state: StateProps;
};

export default function VehiclesTable(props: StatisticallyProps) {
  const navigate = useNavigate();
  const vehicleContext: any = useContext(VehicleContext);
  const { filters, state } = props;

  /**
   *
   */
  const vehiclesHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "numeComplet",
      options: {
        sort: true,
        customBodyRender: (
          numeComplet: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {numeComplet}
          </a>
        ),
      },
    },
    {
      label: "VIN",
      name: "short_vin",
      options: {
        sort: true,
        customBodyRender: (
          short_vin: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {short_vin}
          </a>
        ),
      },
    },
    {
      label: `${Vocabulary.purchasePrice}`,
      name: "pret_achizitie_ron",
      options: {
        sort: true,
        customBodyRender: (
          pret_achizitie_ron: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {formatNumber(pret_achizitie_ron)} Lei
          </a>
        ),
      },
    },
    {
      label: `${Vocabulary.salePrice}`,
      name: "pret_vanzare_ron",
      options: {
        sort: true,
        customBodyRender: (
          pret_vanzare_ron: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {formatNumber(pret_vanzare_ron)} Lei
          </a>
        ),
      },
    },
    {
      label: `${Vocabulary.interestRate}`,
      name: "dobanda",
      options: {
        sort: true,
        customBodyRender: (dobanda: any, tableMeta: any, updateValue: any) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {formatNumber(dobanda)} Lei{" "}
          </a>
        ),
      },
    },
    {
      label: `${Vocabulary.additionalCosts}`,
      name: "suma_costuri_aditionale",
      options: {
        sort: true,
        customBodyRender: (
          suma_costuri_aditionale: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {formatNumber(suma_costuri_aditionale)} Lei{" "}
          </a>
        ),
      },
    },
    {
      label: `${Vocabulary.grossProfit}`,
      name: "profit_brut_vehicul",
      options: {
        sort: true,
        customBodyRender: (
          profit_brut_vehicul: any,
          tableMeta: any,
          updateValue: any
        ) => (
          <a
            href={`/vehiclesDashboard/vehicleDashboard/${
              state?.vehicles[tableMeta.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            {formatNumber(profit_brut_vehicul)} Lei
          </a>
        ),
      },
    }
    // 
    ,
    {
      name: "optiuni",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (index: any, data: any) => (
          <a
            href={`${localUrlEnum.vehicles}/vehicleDetails/${
              state?.vehicles[data.rowIndex].id
            }`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ display: "flex", marginRight: 10 }}>
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  vehicleContext.updateVehicle({});
                  vehicleContext.updateVehicleDocuments({});
                  vehicleContext.updateVehicleImages({});
                  navigate(
                    `${localUrlEnum.vehicles}/vehicleDetails/${
                      state?.vehicles[data.rowIndex].id
                    }`
                  );
                }}
                style={{ marginRight: 10 }}
                aria-label="delete"
              >
                <ModeEditIcon />
              </IconButton>
            </div>
          </a>
        ),
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {


    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state?.vehiclesNumber,
      rowsPerPage: filters.perPage,
      page: filters.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: Vocabulary.pagination,
      },
      rowHover: true,

      onRowClick: (rowData: any, rowState: any) => {
        vehicleContext.updateVehicle({});
        vehicleContext.updateVehicleImages({});
        vehicleContext.updateVehicleDocuments({});
        navigate(
          `/vehiclesDashboard/vehicleDashboard/${
            state?.vehicles[rowState.rowIndex].id
          }`
        );
      },
      onChangePage: (page: number) => {
        navigate(
          `${localUrlEnum.reports}?reports=${filters.reports}?page=${
            page + 1
          }?perPage=${filters.perPage}?startDate=${filters.startDate}?endDate=${
            filters.endDate
          }?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${filters.tva ? filters.tva : null}?sort=${filters.sort}`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `${localUrlEnum.reports}?reports=${
            filters.reports
          }?page=${1}?perPage=${numberOfRows}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${filters.tva ? filters.tva : null}?sort=${filters.sort}`
        );
        window.scrollTo(0, 0);
      },
      onColumnSortChange: (changedColumn: string, direction: string) => {
        navigate(
          `${localUrlEnum.reports}?reports=${
            filters.reports
          }?page=${1}?perPage=${filters.perPage}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}?vehicleStatus=${
            filters.vehicleStatus ? filters.vehicleStatus : null
          }?users=${filters.users ? filters.users : null}?company=${
            filters.company ? filters.company : null
          }?locations=${
            filters.locations ? filters.locations : null
          }?purchaseType=${
            filters.purchaseType ? filters.purchaseType : null
          }?tva=${filters.tva ? filters.tva : null}?sort=${changedColumn},${direction}`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  return (
    <div>
      <MUIDataTable
        title={""}
        data={state?.vehicles}
        columns={vehiclesHeader}
        options={getTableOptions()}
      />
    </div>
  );
}
