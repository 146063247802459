/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import style from "../Styles/optionsVehicle.module.css";
import { europeanDate, formatNumber } from "../Utils/formatDate";
import moment from "moment";
import { VehicleContext } from "../Context/vehicleContext";
import { Vocabulary } from "../Utils/Vocabulary";
import GenericModal from "../Modal/GenericModal";
import { postData } from "../Services/postData";
import { urlEnum } from "../Utils/urlEnum";

type ProfitEstimateDataType = {
  [key: string]: any;
  pret_achizitie_euro: number;
  pret_achizitie_ron: number;
  cheltuieli: number;
  dobanda: string | number;
  profit_brut_vehicul: number;
  profit_brut_vehicul_baza: number;
  profit_net_vehicul: number;
  profit_net_vehicul_baza: number;
  estimare_comision_vanzare: number;
  estimare_comision_volum: number;
  estimare_comision_ca: number;
  estimare_comision_profit: number;
  estimare_comision_total: number;
};

export default function VehicleSideInfo() {
  const vehicleContext: any = useContext(VehicleContext);
  const [estimateProfitModalOpen, setEstimateProfitModalOpen] = useState(false);
  const [sellPriceNet, setSellPriceNet] = useState(0);
  const [profitEstimateData, setProfitEstimateData] =
    useState<ProfitEstimateDataType | null>(null);

  /**
   * get estimate data
   * @param e
   * @returns
   */
  async function getProfitValues(e: any) {
    e.preventDefault();
    const response = await postData(urlEnum.profitEstimates, {
      id: vehicleContext?.state?.vehicle?.id,
      price: sellPriceNet,
    });
    setProfitEstimateData(response.data);

    return false;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        color: "#4D5E80",
      }}
    >
      <Grid container spacing={1} style={{ padding: 5, marginTop: 5 }}>
        <Grid item xs={12} md={4} xl={4}>
          <fieldset style={{ borderRadius: 7 }}>
            <legend>{Vocabulary.vehicleInformation}</legend>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.purchaseUser}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.user_achizitie_r?.name}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.logisticUser}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.user_logistica_r?.name}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.seller}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.vanzator?.name
                  ? vehicleContext?.state?.vehicle?.vanzator?.name
                  : "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.priceOnWebsite}</Typography>
              <Typography className={style.labels}>
                {formatNumber(
                  vehicleContext?.state?.vehicle?.pret_site_vanzare
                )}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.minimumSalePrice}</Typography>
              <Typography className={style.labels}>
                {formatNumber(
                  vehicleContext?.state?.vehicle?.pret_minim_vanzare
                )}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.receptionDate}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.data_receptie
                  ? moment(
                      vehicleContext?.state?.vehicle?.data_receptie
                    ).format(europeanDate)
                  : "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.purchaseDate}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.data_achizitie
                  ? moment(
                      vehicleContext?.state?.vehicle?.data_achizitie
                    ).format(europeanDate)
                  : "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.saleDate}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.data_vanzare
                  ? moment(vehicleContext?.state?.vehicle?.data_vanzare).format(
                      europeanDate
                    )
                  : "-"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.purchaseType}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.tip_achizitie_r?.nume}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor:
                  vehicleContext?.state?.vehicle?.vizibil_site === "1"
                    ? "#b0ffab"
                    : "#ff7979",
                padding: 3,
                borderRadius: 7,
              }}
            >
              <Typography>{Vocabulary.visibleOnSite}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.vizibil_site === "1"
                  ? "DA"
                  : "NU"}
              </Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.tipTVA}</Typography>
              <Typography className={style.labels}>
                {vehicleContext?.state?.vehicle?.tip_tva_r === null
                  ? "-"
                  : vehicleContext?.state?.vehicle?.tip_tva_r?.nume}
              </Typography>
            </div>
          </fieldset>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <fieldset style={{ borderRadius: 7 }}>
            <legend>{Vocabulary.financialDetails}</legend>
            {!vehicleContext?.state?.vehicle?.pret_vanzare_ron ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>{Vocabulary.priceSiteSell}</Typography>
                <div style={{ display: "flex" }}>
                  <Typography className={style.labels}>
                    {vehicleContext?.state?.vehicle?.pret_site_vanzare_ron
                      ? formatNumber(
                          vehicleContext?.state?.vehicle?.pret_site_vanzare_ron
                        )
                      : 0}
                  </Typography>
                  <Typography>{Vocabulary.lei}</Typography>
                </div>
              </div>
            ) : null}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.acquisition}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {vehicleContext?.state?.vehicle?.pret_achizitie_ron
                    ? formatNumber(
                        vehicleContext?.state?.vehicle?.pret_achizitie_ron
                      )
                    : 0}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.expenses}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(vehicleContext?.state?.vehicle?.cheltuieli)}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.commissionsTotals}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.valoare_comisioane
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.rate}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(vehicleContext?.state?.vehicle?.dobanda)}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.valuePerDay}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.valoare_vehicul
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.sale}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.pret_vanzare_ron
                  )
                    ? formatNumber(
                        vehicleContext?.state?.vehicle?.pret_vanzare_ron
                      )
                    : 0}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <Divider style={{ marginTop: 2, marginBottom: 2 }}></Divider>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.grossProfit}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.profit_brut_vehicul
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.netIncome}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.profit_net_vehicul
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.grossProfitBase}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(vehicleContext?.state?.vehicle?.profit_brut_vehicul_baza)}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.netIncomeBase}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(vehicleContext?.state?.vehicle?.profit_net_vehicul_baza)}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div> */}
          </fieldset>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          {/* <fieldset style={{ borderRadius: 7 }}>
            <legend>{Vocabulary.estimate}</legend>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.comisionVanzare}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.estimare_comision_vanzare
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.commissionVolumeEstimate}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.estimare_comision_volum
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.commissionCAEstimate}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.estimare_comision_ca
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>{Vocabulary.commissionProfitEstimate}</Typography>
              <div style={{ display: "flex" }}>
                <Typography className={style.labels}>
                  {formatNumber(
                    vehicleContext?.state?.vehicle?.estimare_comision_profit
                  )}
                </Typography>
                <Typography>{Vocabulary.lei}</Typography>
              </div>
            </div>
          </fieldset> */}

          {/* ESTIMATE PROFIT START */}
          <br />
        
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ color: "white" }}
            onClick={() => setEstimateProfitModalOpen(true)}
          >
            {Vocabulary.profitEstimate}
          </Button>

          <GenericModal
            open={estimateProfitModalOpen}
            onClose={() => setEstimateProfitModalOpen(false)}
            title={Vocabulary.profitEstimate}
          >
            <form onSubmit={getProfitValues}>
              <br />
              <TextField
                type="number"
                value={sellPriceNet}
                fullWidth
                label={Vocabulary.sellPriceNet}
                onChange={(event) =>
                  setSellPriceNet(parseFloat(event.target.value))
                }
              />
              {profitEstimateData && (
                <div>
                  <Divider />
                  <br />
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.sum} ({Vocabulary.euro})
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.pret_achizitie_euro)}
                        </Typography>
                        <Typography>{Vocabulary.euro}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.sum} ({Vocabulary.lei})
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.pret_achizitie_ron)}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.expenses} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.cheltuieli)}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.rate} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.dobanda)}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />

                  <fieldset style={{ borderRadius: 7 }}>
                    <legend>{Vocabulary.estimate}</legend>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.grossProfit} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.profit_brut_vehicul)}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.grossProfitBase} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.profit_brut_vehicul_baza
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.netIncome} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(profitEstimateData.profit_net_vehicul)}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.netIncomeBase} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.profit_net_vehicul_baza
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>

                    <br />
                    <Divider />
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{Vocabulary.comisionVanzare} </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.estimare_comision_vanzare
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.commissionVolumeEstimate}{" "}
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.estimare_comision_volum
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.commissionCAEstimate}{" "}
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.estimare_comision_ca
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.commissionProfitEstimate}{" "}
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.estimare_comision_profit
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {Vocabulary.howMuchMoneyCanYouEarn}{" "}
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <Typography className={style.labels}>
                          {formatNumber(
                            profitEstimateData.estimare_comision_total
                          )}
                        </Typography>
                        <Typography>{Vocabulary.lei}</Typography>
                      </div>
                    </div>
                  </fieldset>
                </div>
              )}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: "white", marginTop: 20 }}
              >
                {Vocabulary.calculate}
              </Button>
            </form>
          </GenericModal>

          {/* ESTIMATE PROFIT END */}
        </Grid>
      </Grid>
    </div>
  );
}
