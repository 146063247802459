/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressActionWrapper from "../ProgressActionComponents/ProgressActionWrapper";
import ProgressAction from "../ProgressActionComponents/ProgressAction";
import UploadElement from "../ProgressActionComponents/UploadElement";
import { Vocabulary } from "../../Utils/Vocabulary";
import ProgressInformation2 from "./ProgressInformation2";
import { VehicleContext } from "../../Context/vehicleContext";
import { useContext, useState } from "react";
import {
  VehicleFileType,
  VehiclesStatus,
  tipFisier,
} from "../../Utils/autocompleteEnum";
import {
  activeStep,
  disableStep,
  verifySellTypeOrReserve,
} from "../../Utils/utils";
import { CacheContext } from "../../Context/cacheContext";
import { VehicleStatusModel } from "../../Models/VehicleModel";
export default function ProgressAction2() {
  const cacheContext = useContext(CacheContext);
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const [open, setOpen] = useState(false);

  const handleOpenCloseModal = () => {
    setOpen(!open);
  };

  /**
   * saves the vehicle in the context
   */
  async function saveVehicle() {
    try {
      const status = cacheContext.cache.vehicleStatus.find(
        (status: VehicleStatusModel) => status.nume === VehiclesStatus.paid
      );
      const done = await vehicleContext.saveWithFile(
        "OPInvoice",
        tipFisier[0].nume,
        VehicleFileType.providerOP,
        () => {
          return (
            vehicleContext?.state?.invoices?.OPInvoice[0]?.name?.length > 0 &&
            vehicleContext?.state?.vehicle?.cursEuro > 0
          );
        },
        ["2.1"],
        null,
        status ? { status_id: status.id } : {}
      );
      if (done) handleOpenCloseModal();
    } catch (e) {
      return false;
    }
  }

  const modifyByArray = [];
  const tempModifyBy = vehicleContext?.state?.vehicle?.modifyBy
    ? vehicleContext?.state?.vehicle?.modifyBy
    : [];
  for (const modification of tempModifyBy) {
    if (modification.index === "2.1") modifyByArray.push(modification);
  }

  return (
    <ProgressActionWrapper
      status={disableStep("2.1", vehicleContext?.state?.vehicle?.indexes)}
      disabled={
        !activeStep(
          "2.1",
          vehicleContext?.state?.vehicle?.indexes,
          verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
        )
      }
      title={Vocabulary.paymentOP}
      modifyBy={modifyByArray}
    >
      <ProgressAction index="2.1">
        <div>
          <ProgressInformation2 disabled></ProgressInformation2>
          <UploadElement
            openModal={open}
            handleModal={handleOpenCloseModal}
            handleSave={saveVehicle}
            handleEditInfo={(event: any) =>
              vehicleContext.updateVehicleInvoiceType({
                name: "OPInvoice",
                value: event,
              })
            }
            fileName={
              vehicleContext?.state?.invoices?.OPInvoice[0] &&
              vehicleContext?.state?.invoices?.OPInvoice[0]?.name
            }
            file={
              vehicleContext?.state?.invoices?.OPInvoice[0] &&
              vehicleContext?.state?.invoices?.OPInvoice[0]?.file
            }
            title={Vocabulary.addOP}
            disabled={
              !disableStep("1.1", vehicleContext?.state?.vehicle?.indexes)
            }
            fileIsRequired={true}
          >
            <ProgressInformation2></ProgressInformation2>
          </UploadElement>
        </div>
      </ProgressAction>
    </ProgressActionWrapper>
  );
}
