import * as Yup from "yup";
import { Vocabulary } from "../Utils/Vocabulary";

export const schema = Yup.object().shape({
  tva_id: Yup.number().required(Vocabulary.required),
  tip_tva_r: Yup.object().required(Vocabulary.required),

  norma_poluare_id: Yup.number().required(Vocabulary.required),
  norma_poluare_r: Yup.object().required(Vocabulary.required),

  furnizor_id: Yup.number().required(Vocabulary.required),
  furnizor_r: Yup.object().required(Vocabulary.required),

  tip_vehicul_id: Yup.number().required(Vocabulary.required),
  tip_vehicul_r: Yup.object().required(Vocabulary.required),

  user_achizitie_id: Yup.number().required(Vocabulary.required),
  user_achizitie_r: Yup.object().required(Vocabulary.required),

  user_logistica_id: Yup.number().required(Vocabulary.required),
  user_logistica_r: Yup.object().required(Vocabulary.required),

  data_vanzare: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .typeError(Vocabulary.invalidDate),

  data_receptie: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .typeError(Vocabulary.invalidDate),

  data_achizitie: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .typeError(Vocabulary.invalidDate),

  brand_id: Yup.number().required(Vocabulary.required),
  brand_r: Yup.object().required(Vocabulary.required),

  model_id: Yup.number().required(Vocabulary.required),
  model_r: Yup.object().required(Vocabulary.required),

  locatie_administrativa_id: Yup.number().required(Vocabulary.required),
  locatie_administrativa_r: Yup.object().required(Vocabulary.required),

  vin: Yup.string()
    .required(Vocabulary.required)
    .min(13, Vocabulary.minLimit)
    .max(18, Vocabulary.maxLimit),

  tip_achizitie_id: Yup.number().required(Vocabulary.required),
  tip_achizitie_r: Yup.object().required(Vocabulary.required),

  firma_achizitie_id: Yup.number().required(Vocabulary.required),
  firma_achizitie_r: Yup.object().required(Vocabulary.required),

  tip_caroserie_id: Yup.number().required(Vocabulary.required),
  tip_caroserie_r: Yup.object().required(Vocabulary.required),

  culoare_id: Yup.number().required(Vocabulary.required),
  culoare_r: Yup.object().required(Vocabulary.required),

  retromobil: Yup.string(),

  optiuni_culoare_id: Yup.number().required(Vocabulary.required),
  optiune_culoare_r: Yup.object().required(Vocabulary.required),

  tara_origine_id: Yup.number().required(Vocabulary.required),
  tara_origine_r: Yup.object().required(Vocabulary.required),

  // kilometri: Yup.object().required(Vocabulary.required),
  // an_fabricatie: Yup.string().required(Vocabulary.required),
  // nr_locuri: Yup.string().required(Vocabulary.required),
  // numar_portiere: Yup.string().required(Vocabulary.required),
});

export const AutocompleteSchema = Yup.object().shape({
  nume: Yup.string().required(Vocabulary.required),
});

export const AutocompleteSchemaForAddress = Yup.object().shape({});

export const AutocompleteSchemaForFirmaAchizitie = Yup.object().shape({
  nume_firma: Yup.string().required(Vocabulary.required),
});

export const AutocompleteSchemaForTipTVA = Yup.object().shape({
  // nume: Yup.string().required(Vocabulary.required),
  // valoare:Yup.string().required(Vocabulary.required)
});
export const AutocompleteSchemaforDrivers = Yup.object().shape({
  name: Yup.string().required(Vocabulary.required),
});
