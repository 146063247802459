import { Vocabulary } from "../Vocabulary";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export const ROUTER_LOGISTICS_DEPARTMENT = {
  routes: [
    "/",
    "/logistics",
    "/vehiclesDashboard/vehicleDetails/:id?",
    "/vehiclesDashboard",
    "/vehiclesDashboard/:id?",
    "/anaf/notifications",
    "/anaf",
    "/controlPanel",
    "*",
  ],
};

export const MAIN_SLIDER_LOGISTICS_DEPARTMENT = [
  {
    name: Vocabulary.anafTitle,
    path: "/anaf/notifications",
    icon: <AccountBalanceIcon />,
    orderId: 15.5,
  },
  //DOAR DE SCOS COMENTARIILE SI APARARE PANOUL DE CONTROL
  // {
  //   name: Vocabulary.controlPanel,
  //   path: "/controlPanel",
  //   icon: <ViewCarouselIcon />,
  //   orderId: 0,
  // }
];

export const VEHICLE_LOGISTICS_DEPARTMENT = [
  {
    name: Vocabulary.detaliiVehicul,
    path: "/vehiclesDashboard/vehicleDetails/",
    icon: <NoCrashIcon />,
    id: "vehicleDetails",
    orderNumber: 1,
  },
];

export const DINAMIC_TABS_LOGISTICS_DEPARTMENT = null;

export const MAIN_TABLE_LOGISTICS_DEPARTMENT = { route: "vehicleDetails" };

export const MAIN_HEADER_LOGISTICS_DEPARTMENT = "vehicleDetails";
