/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useMemo, useState } from "react";
import style from "../../Styles/filters.module.css";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TaxiAlertIcon from "@mui/icons-material/TaxiAlert";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PieChartIcon from "@mui/icons-material/PieChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TuneIcon from "@mui/icons-material/Tune";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { CacheContext } from "../../Context/cacheContext";
import { isMobile } from "../../Utils/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Vocabulary } from "../../Utils/Vocabulary";
import { europeanDate } from "../../Utils/formatDate";
import { ReportsTab } from "../../Utils/autocompleteEnum";
import { StatusModel } from "../../Models/Models";

type FiltersProps = {
  onChangeTextField: (event: any, newValue: any, name: string) => void;
  handleAccordion: (value: boolean) => void;
  handleChangeStartDate: (event: any) => void;
  handleChangeEndDate: (event: any) => void;
  exportExcel: () => void;
  data: any;
  handleToggleModal: () => void;
};
export default function Filters(props: FiltersProps) {
  const {
    onChangeTextField,
    handleAccordion,
    handleChangeStartDate,
    handleChangeEndDate,
    data,
    handleToggleModal,
  } = props;
  const cacheContext: any = useContext(CacheContext);
  const [hideFilters, setHideFilters] = useState(true);

  //Get selected data from cache
  //user
  const localUser = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.users?.find((user: any) => {
        if (+user.id === +data?.users) return user;
        return null;
      })
    );
  }, [cacheContext.cache, data?.users]);

  //status
  const localStatus = useMemo(() => {
    const statusesArray: StatusModel[] = [];
    if (data?.vehicleStatus)
      if (JSON.parse(data?.vehicleStatus)?.length) {
        const ids = JSON.parse(data.vehicleStatus);
        if (ids?.length) {
          ids.forEach((id: number) => {
            const status: StatusModel = cacheContext?.cache?.vehicleStatus.find(
              (status: StatusModel) => {
                return status.id == id;
              }
            );
            if (status) statusesArray.push(status);
          });
        }
      }
    return statusesArray;
    // return (
    //   cacheContext.cache.length !== 0 &&
    //   cacheContext?.cache?.vehicleStatus?.find((status: any) => {
    //     if (+status.id === +data?.vehicleStatus) return status;
    //     return null;
    //   })
    // );
  }, [cacheContext.cache, data?.vehicleStatus]);

  //company
  const localCompany = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.company?.find((company: any) => {
        if (+company.id === +data?.company) return company;
        return null;
      })
    );
  }, [cacheContext.cache, data?.company]);

  //location
  const localLocation = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.locations?.find((location: any) => {
        if (+location.id === +data?.locations) return location;
        return null;
      })
    );
  }, [cacheContext.cache, data?.locations]);

  //purchaseType
  const localPurchaseType = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.purchaseType?.find((purchaseType: any) => {
        if (+purchaseType.id === +data?.purchaseType) return purchaseType;
        return null;
      })
    );
  }, [cacheContext.cache, data?.purchaseType]);

  //tva
  const localTva = useMemo(() => {
    return (
      cacheContext.cache.length !== 0 &&
      cacheContext?.cache?.tvaType?.find((tva: any) => {
        if (+tva.id === +data?.tva) return tva;
        return null;
      })
    );
  }, [cacheContext.cache, data?.tva]);

  return (
    <div className={hideFilters ? style.container : style.containerHide}>
      <div className={hideFilters ? style.filters : style.filtersHide}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.startDate}
              value={moment(data?.startDate)}
              maxDate={moment(data?.endDate)}
              onChange={handleChangeStartDate}
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={Vocabulary.endDate}
              value={moment(data?.endDate)}
              minDate={moment(data?.startDate)}
              onChange={handleChangeEndDate}
              format={europeanDate}
              slotProps={{ textField: { size: "small", fullWidth: true } }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="users"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.users}
            value={localUser ? localUser : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "users");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.usersName}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        {data?.reports !== ReportsTab.statistically ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
              <Autocomplete
                id="vehicleStatus"
                multiple
                disableClearable={true}
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="small"
                value={localStatus}
                options={cacheContext.cache.vehicleStatus}
                onChange={(event: any, newValue: any) => {
                  const statusIds: any[] = [];
                  if (newValue) {
                    newValue?.forEach((status: StatusModel) => {
                      statusIds.push(status?.id);
                    });
                  }
                  onChangeTextField(event, statusIds, "vehicleStatus");
                  handleAccordion(false);
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.statusVehicles}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
          </>
        ) : null}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TaxiAlertIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="company"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume_firma}
            size="small"
            options={cacheContext.cache.company}
            value={localCompany ? localCompany : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "company");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.companies}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationCityIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="locations"
            disablePortal
            freeSolo={false}
            getOptionLabel={(option: any) => option.nume}
            size="small"
            value={localLocation ? localLocation : null}
            options={cacheContext.cache.locations}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "locations");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.administrativeLocation}
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PieChartIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="purchaseType"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.purchaseType}
            value={localPurchaseType ? localPurchaseType : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "purchaseType");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.purchaseType}
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PriceCheckIcon sx={{ color: "#4d5e80", mr: 1, my: 2 }} />
          <Autocomplete
            id="tva"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.nume}
            size="small"
            options={cacheContext.cache.tvaType}
            value={localTva ? localTva : null}
            onChange={(event: any, newValue: any) => {
              onChangeTextField(event, newValue, "tva");
              handleAccordion(false);
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Vocabulary.tipTVA}
                variant="outlined"
                size="small"
              />
            )}
          ></Autocomplete>
        </Box>

        {data?.reports === ReportsTab.vehicles ? (
          <Button
            variant="outlined"
            style={{ color: "#ff6633", borderColor: "#ff6633", marginTop: 5 }}
            onClick={handleToggleModal}
          >
            <CloudDownloadIcon style={{ marginRight: 10 }} />
            {Vocabulary.downloadExcel}
          </Button>
        ) : null}
      </div>
      <div style={{ position: "absolute", bottom: 10, left: 8 }}>
        {isMobile() ? null : hideFilters ? (
          <Tooltip title={Vocabulary.hideFilters}>
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#ff6633", color: "#fff" }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filtre">
            <IconButton
              onClick={() => setHideFilters(!hideFilters)}
              style={{ backgroundColor: "#4448", color: "#fff" }}
            >
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
