/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/urlEnum";
import { AdministrativeLocation, UserData } from "./UsersDashboard";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { CacheContext } from "../../Context/cacheContext";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "../../Utils/utils";
import { Visibility } from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { GenericModel } from "../../Models/Models";

type UserProps = {
  open: boolean;
  title: string;
  visibilityUser: boolean;
  editedUser: UserData | null;
  onClose: (shouldRefetch?: boolean) => void;
};

const initialState: UserData = {
  name: "",
  telefon: "",
  email: "",
  locatie_administrativa: {
    id: "",
    nume: "",
  },
  locatie_administrativa_id: null,
  comision_user: 0,
  base_net_salary: "",
  is_managing_users: false,
  password: "",
  confirmPassword: "",
  roles: [],
  is_subcontractor: false,
};
export default function User(props: UserProps) {
  const notifyError = (message: string) => toast.error(message);
  const { open, title, visibilityUser, editedUser, onClose } = props;
  const [changePassword, setChangePassword] = useState(false);
  const [data, setData] = useState<UserData>(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const cacheContext = useContext(CacheContext) as any;

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== data.password) return false;
      return true;
    });

    if (editedUser) {
      console.log(editedUser);
      const newUserData = Object.assign({}, editedUser);
      cacheContext.cache?.locations.forEach(
        (location: AdministrativeLocation) => {
          if (location.id === editedUser.locatie_administrativa_id)
            newUserData.locatie_administrativa = {
              id: location.id,
              nume: location.nume,
            };
        }
      );
      setData(newUserData);
    } else setData(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedUser]);

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({ ...data, [event.target.name]: event.target.value });
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== data.password) return false;
      return true;
    });
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeLocation(event: any, newValue: AdministrativeLocation) {
    const newData = Object.assign({}, data);
    if (newValue) newData.locatie_administrativa = newValue;
    else {
      cacheContext.cache?.locations.forEach(
        (location: AdministrativeLocation) => {
          if (location.id === data.locatie_administrativa_id)
            newData.locatie_administrativa = {
              id: location.id,
              nume: location.nume,
            };
        }
      );
    }

    setData(newData);
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeRoles(event: any, newValue: any) {
    const newData = Object.assign({}, data);
    if (newValue) newData.roles = newValue;
    else newData.roles = [];
    setData(newData);
  }
  /**
   * Save user
   */
  function saveUser() {
    if (data.locatie_administrativa.id === "") {
      notifyError(Vocabulary.selectLocation);
      return;
    }
    if (data.roles && data.roles.length === 0) {
      notifyError(Vocabulary.selectRoles);
      return;
    }
    const roles = data?.roles && data?.roles?.map((role: any) => role.id);
    let newUser = {
      name: data.name,
      telefon: data.telefon,
      email: data.email,
      locatie_administrativa_id: data.locatie_administrativa.id,
      base_net_salary: data.base_net_salary,
      comision_user: data.comision_user,
      managed_user_ids: data?.managed_user_ids,
      is_managing_users: data?.is_managing_users,
      roles: roles,
      confirmPassword: data.confirmPassword,
      is_subcontractor: data.is_subcontractor,
    } as any;
    if (editedUser) {
      if (changePassword) {
        newUser = {
          ...newUser,
          password: data.password,
        };
      }
      updateData(`${urlEnum.users}/${editedUser.id}`, newUser)
        .then((response) => {
          cacheContext.setCache({
            ...cacheContext.cache,
            users: response.data.users,
          });
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    } else {
      newUser = {
        ...newUser,
        password: data.password,
      };
      postData(urlEnum.users, newUser)
        .then((response) => {
          cacheContext.setCache({
            ...cacheContext.cache,
            users: response.data.users,
          });
          setData(initialState);
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    }
  }

  /**
   *
   * @returns values for autocomplete from url
   */
  const getValuesForMultipleAutocomplete = () => {
    const array: number[] = [];
    if (data?.managed_user_ids) {
      if (data?.managed_user_ids?.length) {
        let foundedObject = null;
        data?.managed_user_ids?.forEach((user: number) => {
          foundedObject = cacheContext.cache?.users.find(
            (type: GenericModel) => {
              return type.id == user;
            }
          );
          if (foundedObject) array.push(foundedObject);
        });
      }
    }
    return array && array.length ? array : [];
  };

  /**
   *
   *
   */
  const handleChangeMultipleAutocomplete = (
    event: SyntheticEvent<Element, Event>,
    newValue: number[]
  ) => {
    const newData = Object.assign({}, data);
    if (newValue) newData.managed_user_ids = newValue;
    else newData.managed_user_ids = [];
    setData(newData);
  };

  return (
    <>
      <ToastContainer />
      <GenericModal open={open} onClose={() => onClose(false)} title={title}>
        <ValidatorForm onSubmit={saveUser}>
          <Grid
            container
            spacing={isMobile() ? 1 : 4}
            style={{
              paddingBottom: 15,
              zIndex: 100,
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                name="name"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.userName}
                value={data.name}
                disabled={visibilityUser}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="roles"
                multiple
                freeSolo={false}
                disablePortal
                disabled={visibilityUser}
                getOptionLabel={(option: any) => option.name}
                getOptionDisabled={(option) =>
                  !!(
                    data?.roles &&
                    data.roles.find(
                      (element: any) => element.name === option.name
                    )
                  )
                }
                value={data.roles}
                options={cacheContext.cache?.roles}
                onChange={(event: any, newValue: any) =>
                  handleChangeRoles(event, newValue)
                }
                sx={{ width: "100%", zIndex: 10000 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.roles}
                    variant="outlined"
                    disabled={visibilityUser}
                    error={data.roles?.length === 0}
                    helperText={data.roles?.length === 0 && Vocabulary.required}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                name="telefon"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.phone}
                value={data.telefon}
                disabled={visibilityUser}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                type="email"
                fullWidth
                name="email"
                validators={["required", "isEmail"]}
                errorMessages={[
                  Vocabulary.requiredField,
                  Vocabulary.incorrectEmail,
                ]}
                label={Vocabulary.email}
                value={data.email}
                disabled={visibilityUser}
                onChange={handleChangeTextFields}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                type="number"
                name="base_net_salary"
                //validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.baseNetSalary}
                value={data.base_net_salary ?? ""}
                disabled={visibilityUser}
                onChange={handleChangeTextFields}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                type="number"
                name="comision_user"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                label={Vocabulary.userComision}
                value={data.comision_user}
                disabled={visibilityUser}
                onChange={handleChangeTextFields}
              />
            </Grid> */}

            <Grid item md={6} xs={12}>
              <Autocomplete
                id="locatie_administrativa_id"
                freeSolo={false}
                disablePortal
                disableClearable
                disabled={visibilityUser}
                getOptionLabel={(option: any) => option.nume}
                options={cacheContext.cache?.locations}
                value={data.locatie_administrativa}
                onChange={(event: any, newValue: any) =>
                  handleChangeLocation(event, newValue)
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="locatie_administrativa"
                    label={Vocabulary.location}
                    variant="outlined"
                    disabled={visibilityUser}
                    error={!data.locatie_administrativa.id}
                    helperText={
                      !data.locatie_administrativa.id && Vocabulary.required
                    }
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              {editedUser ? (
                <TextValidator
                  fullWidth
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  label={Vocabulary.password}
                  sx={{ with: "100%" }}
                  validators={[
                    changePassword ? "required" : "matchRegexp:^.{8,100}$",
                    "matchRegexp:^.{8,100}$",
                  ]}
                  errorMessages={[
                    Vocabulary.requiredField,
                    "Minim 8 caractere",
                  ]}
                  error={
                    data.password && data.confirmPassword !== data.password
                  }
                  helperText={
                    data.password &&
                    data.confirmPassword !== data.password &&
                    Vocabulary.passwordNoMatch
                  }
                  value={data.password}
                  disabled={!changePassword}
                  onChange={handleChangeTextFields}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Checkbox
                          onClick={() => setChangePassword(!changePassword)}
                          checked={changePassword}
                          disabled={visibilityUser}
                        />
                        <span
                          style={{
                            fontSize: 12,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>{"Schimba"}</div>
                          <div style={{ marginTop: -10 }}>{"parola"}</div>
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextValidator
                  fullWidth
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  label={Vocabulary.password}
                  validators={["required", "matchRegexp:^.{8,100}$"]}
                  errorMessages={[
                    Vocabulary.requiredField,
                    "Minim 8 caractere",
                    Vocabulary.passwordNoMatch,
                  ]}
                  error={
                    data.password && data.confirmPassword !== data.password
                  }
                  helperText={
                    data.password &&
                    data.confirmPassword !== data.password &&
                    Vocabulary.passwordNoMatch
                  }
                  value={data.password}
                  disabled={visibilityUser}
                  onChange={handleChangeTextFields}
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {changePassword || !editedUser ? (
                <TextValidator
                  fullWidth
                  type={!showPassword ? "password" : "text"}
                  name="confirmPassword"
                  label={Vocabulary.confirmPassword}
                  validators={[
                    "required",
                    "matchRegexp:^.{0,100}$",
                    "isPasswordMatch",
                  ]}
                  errorMessages={[
                    Vocabulary.requiredField,
                    "Minim 8 caractere",
                    Vocabulary.passwordNoMatch,
                  ]}
                  error={
                    data.password && data.confirmPassword !== data.password
                  }
                  helperText={
                    data.password &&
                    data.confirmPassword !== data.password &&
                    Vocabulary.passwordNoMatch
                  }
                  disabled={visibilityUser}
                  value={data.confirmPassword}
                  onChange={handleChangeTextFields}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={visibilityUser}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {!showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : null}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                className="manageOtherUsers"
                sx={{ marginLeft: "-7px", fontSize: "2rem !important" }}
                control={
                  <Checkbox
                    checked={data.is_managing_users}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setData({
                        ...data,
                        is_managing_users: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography style={{ fontSize: "1rem" }}>
                    {Vocabulary.manageOtherUsers}
                  </Typography>
                }
              />
              {data?.is_managing_users && (
                <Autocomplete
                  id="managed_user_ids"
                  freeSolo={false}
                  fullWidth
                  disablePortal
                  multiple
                  getOptionLabel={(option: any) => option?.nume}
                  isOptionEqualToValue={(option: any, value) =>
                    value ? option.id === value.id : false
                  }
                  getOptionDisabled={(option) => option?.id == editedUser?.id} //to not assign himself
                  size="small"
                  value={getValuesForMultipleAutocomplete()}
                  options={cacheContext.cache?.users}
                  onChange={(event: any, newValue: any) => {
                    newValue = newValue.map((value: GenericModel) => {
                      return value.id;
                    });
                    handleChangeMultipleAutocomplete(event, newValue);
                  }}
                  sx={{ maxWidth: "100%", marginLeft: "0px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.user}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                className="is_subcontractor"
                sx={{ marginLeft: "-7px", fontSize: "2rem !important" }}
                control={
                  <Checkbox
                    checked={data.is_subcontractor}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setData({
                        ...data,
                        is_subcontractor: event.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography style={{ fontSize: "1rem" }}>
                    {Vocabulary.subcontractor}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {visibilityUser ? null : (
            <Grid container spacing={isMobile() ? 1 : 4}>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClose(false)}
                  fullWidth
                >
                  {Vocabulary.cancel}
                </Button>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ color: "white" }}
                >
                  {Vocabulary.save}
                </Button>
              </Grid>
            </Grid>
          )}
        </ValidatorForm>
      </GenericModal>
    </>
  );
}
