import { useEffect, useState } from "react";
import GenericModal from "../../Modal/GenericModal";
import { urlEnum } from "../../Utils/urlEnum";

type PreviewImageProps = {
  title: string | undefined;
  open: boolean;
  onClose: () => void;
  url: string | undefined;
};
export default function PreviewImage(props: PreviewImageProps) {
  const { title, open, onClose, url } = props;
  const [path, setPath] = useState<string | undefined | null>(null);

  useEffect(() => {
    const newPath = url?.replace(`${urlEnum.vehicles}`, "/api");
    setPath(newPath);
  }, [url]);

  return (
    <GenericModal
      open={open}
      onClose={onClose}
      title={title || ""}
      maxWidth={1000}
    >
      <div style={{ textAlign: "center" }}>
        <img style={{ width: "80%" }} src={`${path}`} alt="No file" />
      </div>
    </GenericModal>
  );
}
