/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../Styles/genericModal.module.css";
import { isMobile } from "../Utils/utils";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { useContext, useEffect, useState } from "react";
import { urlEnum } from "../Utils/urlEnum";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import { CacheContext } from "../Context/cacheContext";
import { getData } from "../Services/getData";
import { Note } from "../Models/Models";
import { Vocabulary } from "../Utils/Vocabulary";
type Props = {
  open: boolean;
  onClose: () => void;
  children: any;
  buttons?: any;
  title: string;
  maxWidth?: any;
  zIndex?: number;
  notes?: any;
};

function GenericModal(props: Props) {
  const { open, children, zIndex, onClose, title, maxWidth, buttons } = props;
  const [openModalNotes, setOpenModalNotes] = useState(false);
  const [notes, setNotes]: any = useState(new Note());
  const cacheContext = useContext(CacheContext);

  useEffect(() => {
    props.notes && getNotes();
  }, []);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getNotes() {
    const url = `${urlEnum.notes}/${props.notes.entityId.toString()}/${
      props.notes.entity
    }`;

    getData(url)
      .then((res: any) => {
        res.data.note === null ? setNotes(new Note()) : setNotes(res.data.note);
      })
      .catch((err: any) => {
        //
      });
  }

  function addNewNote() {
    const url = `${urlEnum.notes}`;
    if (notes.note !== "") {
      postData(url, {
        user_id: localStorage.getItem("useId"),
        entity_id: props.notes.entityId.toString(),
        entity: props.notes.entity,
        note: notes.note,
      })
        .then(() => {
          getNotes();
        })
        .catch(() => {
          //
        });
    }
  }

  function updateNote() {
    const url = `${urlEnum.notes}/${notes.id}`;

    updateData(url, {
      user_id: localStorage.getItem("useId"),
      entity_id: props.notes.entityId.toString(),
      entity: props.notes.entity,
      note: notes.note,
    })
      .then(() => {
        //
      })
      .catch(() => {
        //
      });
  }

  function handleChangeNote(text: any) {
    const newNote = { ...notes };
    newNote.note = text;
    setNotes(newNote);
  }

  return (
    <>
      <Dialog
        // onClose={onClose}
        open={open}
        id="genericModal"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: "7px !important",
              width: "100%",
              maxWidth: maxWidth ? maxWidth : 750,
              overflow: isMobile() ? "scroll" : "visible",
              zIndex: zIndex ? zIndex : 100,
              position: "sticky",
            },
          },
        }}
        fullScreen={isMobile()}
      >
        <DialogTitle
          style={{ marginBottom: -10, textTransform: "capitalize" }}
          className={styles.dialogTitle}
        >
          {openModalNotes ? (
            <Paper className={styles.notePaper}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p
                  style={{
                    fontSize: 10,
                    fontStyle: "italic",
                    marginBottom: -8,
                  }}
                >
                  {
                    cacheContext.cache.users.find(
                      (user: any) => user.id === parseInt(notes?.user_id)
                    )?.nume
                  }
                </p>
                <CloseIcon
                  onClick={() => setOpenModalNotes(false)}
                  className={styles.closeIconForNotes}
                />
              </div>
              <TextField
                placeholder={Vocabulary.noteMessage}
                style={{ width: "100%" }}
                id="obsTVA"
                value={notes?.note}
                onChange={(e) => handleChangeNote(e.target.value)}
                size="small"
                onBlur={notes.id ? () => updateNote() : () => addNewNote()}
                variant="outlined"
                multiline
                rows={7}
              />
            </Paper>
          ) : null}

          <p className={styles.title}>{title}</p>
          {props.notes ? (
            <p className={styles.notes}>
              {notes?.note ? (
                <Badge badgeContent=" " variant="dot" color="primary">
                  <SpeakerNotesIcon
                    onClick={() => setOpenModalNotes(!openModalNotes)}
                    style={{ cursor: "pointer" }}
                  ></SpeakerNotesIcon>
                </Badge>
              ) : (
                <SpeakerNotesIcon
                  onClick={() => setOpenModalNotes(!openModalNotes)}
                  style={{ cursor: "pointer" }}
                ></SpeakerNotesIcon>
              )}
            </p>
          ) : null}
          <p className={styles.close}>
            <CloseIcon onClick={onClose} className={styles.closeIcon} />
          </p>
        </DialogTitle>
        <DialogContent style={{ overflowY: buttons ? "scroll" : "inherit" }}>
          {children}
        </DialogContent>
        <DialogActions>{buttons}</DialogActions>
      </Dialog>
    </>
  );
}

export default GenericModal;
