/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import MUIDataTable from "mui-datatables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Log from "./Log";
import LogsFilters from "./LogsFilters";
import moment from "moment";
import style from "../../Styles/tableFilter.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from '@mui/icons-material/Tune';
import { isMobile } from "../../Utils/utils";
import { tableLayoutOnMobile } from "../../Utils/Constants";

type State = {
  logs: Array<any>;
  logsNumber: number;
};

export default function Logs() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [accordeonState, setAccordeonState] = useState<boolean>(true);
  const [filters, setFilters] = useState<any>({
    page: 1,
    perPage: 10,
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    user: "",
    action: "",
    model: "",
  });
  const [viewedLog, setViewedLog] = useState<any>(null);
  const [state, setState] = useState<State>({
    logs: new Array<any>(),
    logsNumber: 0,
  });

  /**
   * Get logs
   */
  useEffect(() => {
    const search = location.search.split("?");
    const newFilters = {
      page: 1,
      perPage: 10,
      startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      user: "",
      action: "",
      model: "",
    } as any;
    if (search.length === 1 && search[0] === "") {
      navigate(
        `/logs?page=${filters.page}?perPage=${filters.perPage}?startDate=${filters.startDate}?endDate=${filters.endDate}`
      );
    } else {
      search.forEach((value: any) => {
        if (value !== "") {
          const values = value.split("=");
          if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
          else
            newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        }
      });
      getLogs(newFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getLogs(newFilters: any) {
    setFilters(newFilters);
    const url = `${urlEnum.logs}/${newFilters.page}/${newFilters.perPage}/${
      newFilters.startDate
    }/${moment(newFilters.endDate, "YYYY-MM-DD")
      .add(1, "days")
      .format("YYYY-MM-DD")}/${
      newFilters.user && newFilters.user !== "" ? newFilters.user : null
    }/${
      newFilters.action && newFilters.action !== "" ? newFilters.action : null
    }/${newFilters.model && newFilters.model !== "" ? newFilters.model : null}`;
    getData(url)
      .then((res) => {
        setState({
          ...state,
          logs: res.data.logs,
          logsNumber: res.data.logsNumber,
        });
        setFilters(newFilters);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  const logsHeader = [
    {
      label: `${Vocabulary.name}`,
      name: "user_name",
      options: {
        sort: false,
      },
    },
    {
      label: `${Vocabulary.actions}`,
      name: "event",
      options: {
        sort: false,
        customBodyRender: (event: any) => (
          <Typography>{Vocabulary[event]}</Typography>
        ),
      },
    },
    {
      label: `${Vocabulary.model}`,
      name: "auditable_type",
      options: {
        sort: false,
        customBodyRender: (model: any) => {
          model = model.replace("App\\Models\\", "");
          model = model.replace("App\\", "");
          return <Typography>{model}</Typography>;
        },
      },
    },
    {
      label: `${Vocabulary.date}`,
      name: "data_event",
      options: {
        sort: false,
      },
    },
    {
      label: "",
      name: "",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,

        empty: true,
        customBodyRenderLite: (rowIndex: number) => {
          return (
            <div style={{ marginTop: -10, marginBottom: -10, float: "right" }}>
              <ToggleButtonGroup exclusive aria-label="text alignment">
                <Tooltip title={Vocabulary.visibility}>
                  <ToggleButton
                    onClick={() => {
                      setOpenViewModal(true);
                      setViewedLog(state.logs[rowIndex]);
                    }}
                    value="center"
                    aria-label="centered"
                  >
                    <VisibilityIcon />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  function getTableOptions() {
  
    return {
      selectableRows: "none" as any,
      responsive: tableLayoutOnMobile,
      viewColumns: false,
      rowsPerPageOptions: [10, 20, 50, 100],
      confirmFilters: true,
      filter: false,
      print: false,
      download: false,
      fixedHeader: true,
      count: state.logsNumber,
      rowsPerPage: filters.perPage,
      page: filters.page,
      search: false,
      serverSide: true,
      sort: true,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination:Vocabulary.pagination
      },
      onChangePage: (page: number) => {
        navigate(
          `/logs?page=${page + 1}?perPage=${filters.perPage}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}${
            filters.user !== "" ? `?user=${filters.user}` : ""
          }${
            filters.action && filters.action !== ""
              ? `?action=${filters.action}`
              : ""
          }${
            filters.model && filters.model !== ""
              ? `?model=${filters.model}`
              : ""
          }`
        );
        window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        navigate(
          `/logs?page=${1}?perPage=${numberOfRows}?startDate=${
            filters.startDate
          }?endDate=${filters.endDate}${
            filters.user !== "" ? `?user=${filters.user}` : ""
          }${
            filters.action && filters.action !== ""
              ? `?action=${filters.action}`
              : ""
          }${
            filters.model && filters.model !== ""
              ? `?model=${filters.model}`
              : ""
          }`
        );
        window.scrollTo(0, 0);
      },
    };
  }

  /**
   *
   */
  function handleCloseViewModal() {
    setOpenViewModal(false);
    setViewedLog(null);
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeAutocomplete(event: any, newValue: any, name: string) {
    let loc = location.search;
    let newLocation = loc.split("?");
    if (loc.includes(name)) {
      newLocation = newLocation.map((l: string) => {
        if (l.includes(name)) {
          if (newValue) {
            return `${name}=${newValue.id}`;
          }
          return "";
        }
        return l;
      });
      newLocation = newLocation.filter((l) => l !== "");
      loc = newLocation.join("?");
      loc = `?${loc}`;
    } else {
      if (newValue) loc += `?${name}=${newValue.id}`;
    }
    navigate(loc);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeStartDate(event: any) {
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("startDate")) {
        return `startDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }

  /**
   *
   * @param {*} event
   * @returns
   */
  function handleChangeEndDate(event: any) {
    let loc = location.search;
    let newLocation = loc.split("?");
    newLocation = newLocation.map((l: string) => {
      if (l.includes("endDate")) {
        return `endDate=${moment(event).format("YYYY-MM-DD")}`;
      }

      return l;
    });
    loc = newLocation.join("?");
    navigate(loc);
  }

  function handleChangeTextFields(event: any) {
    setFilters({ ...filters, model: event.target.value });
  }
  /**
   *
   * @param event
   */
  function onBlurTextFields() {
    let loc = location.search;
    let newLocation = loc.split("?");
    if (loc.includes("model")) {
      newLocation = newLocation.map((l: string) => {
        if (l.includes("model")) {
          if (filters.model === "") return "";
          return `model=${filters.model}`;
        }

        return l;
      });
      loc = newLocation.join("?");
    } else {
      loc += `?model=${filters.model}`;
    }
    navigate(loc);
  }

  return (
    <div className={style.containerTable}>
      <Accordion onChange={()=>{setAccordeonState(!accordeonState)}} expanded={accordeonState}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          
        >
          <TuneIcon style={{marginRight:10}}></TuneIcon>
          <Typography style={{marginTop:1}}>Filtre </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LogsFilters
            filters={filters}
            handleChangeAutocomplete={handleChangeAutocomplete}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            handleChangeTextFields={handleChangeTextFields}
            onBlurTextFields={onBlurTextFields}
          />
        </AccordionDetails>
      </Accordion>

      <ThemeProvider theme={getMuiTheme()}>
        <div style={{ marginTop:isMobile()?10:20 }}>
          <MUIDataTable
            title={""}
            data={state.logs}
            columns={logsHeader}
            options={getTableOptions()}
          />
        </div>
      </ThemeProvider>
      <Log
        open={openViewModal}
        onClose={handleCloseViewModal}
        log={viewedLog}
      />
    </div>
  );
}
