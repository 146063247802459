/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isMobile } from "../../Utils/utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "../../Styles/reports.module.css";
import GraphicBar from "./GraphicBar";
import {
  CostsGraphsProps,
  CostsInfoProps,
  ProfitGraphsProps,
  ProfitInfoProps,
  VehiclesInfoProps,
} from "./Reports";
import { formatNumber } from "../../Utils/formatDate";
import { useRef } from "react";

type StatisticallyProps = {
  costsGraphs: CostsGraphsProps;
  grossProfitGraphs: ProfitGraphsProps;
  netIncomeGraphs: ProfitGraphsProps;
  vehiclesInfo: VehiclesInfoProps | null;
  profitInfo: ProfitInfoProps | null;
  costsInfo: CostsInfoProps | null;
  purchaseInfo?: ProfitGraphsProps;
  vehicleNumberGraphs?: ProfitGraphsProps;
  saleValueInfo?: ProfitGraphsProps;
  saleDurationInfo?: ProfitGraphsProps;
  interestRateInfo?: ProfitGraphsProps;
  saleDurationAverageInfo?: ProfitGraphsProps;
  openGraphs?: boolean;
  openSecondGraphs?: any;
};
export default function Statistically(props: StatisticallyProps) {
  const sectionRef: any = useRef(null);

  const scrollToSection = () => {
    // Use scrollIntoView to scroll to the section
    sectionRef.current &&
      sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const {
    costsGraphs,
    grossProfitGraphs,
    vehicleNumberGraphs,
    // netIncomeGraphs,
    vehiclesInfo,
    purchaseInfo,
    profitInfo,
    costsInfo,
    saleValueInfo,
    // saleDurationInfo,
    // interestRateInfo,
    saleDurationAverageInfo,
    openGraphs,
    openSecondGraphs,
  } = props;

  return (
    <Paper
      style={{
        padding: 15,
        marginBottom: 40,
        borderRadius: "0px 0px 15px 15px ",
      }}
    >
      <div
        className={style.statisticallyContainer}
        style={{
          flexDirection: isMobile() ? "column" : "row",
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          xl={12}
          md={12}
          xs={12}
        >
          <Grid item xl={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Informatii vehicule</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                xl={12}
                md={12}
                xs={12}
              >
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.totalVehicles} </span>
                    {vehiclesInfo?.totalVehicles}
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.purchaseValue}</span>
                    {formatNumber(vehiclesInfo?.purchaseValue)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.saleValue}</span>
                    {formatNumber(vehiclesInfo?.saleValue)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.statisticallySaleDuration}</span>
                    {vehiclesInfo?.saleDuration}
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.saleDurationAverage}</span>
                    {vehiclesInfo?.saleDurationAverage}
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.interestRate}</span>
                    {formatNumber(vehiclesInfo?.interestRate)} Lei
                  </p>
                </Grid>
                <div ref={sectionRef}> </div>

                {openGraphs ? (
                  <>
                    <Grid item xl={6} xs={12}>
                      <GraphicBar
                        keys={vehicleNumberGraphs?.keys}
                        data={vehicleNumberGraphs?.profit}
                        title={Vocabulary.vehiclesNumber}
                        enableLabel={true}
                        value={formatNumber(vehiclesInfo?.totalVehicles)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                      />
                    </Grid>
                    <Grid item xl={6} xs={12}>
                      <GraphicBar
                        keys={purchaseInfo?.keys}
                        data={purchaseInfo?.profit}
                        title={Vocabulary.purchaseValue}
                        value={formatNumber(vehiclesInfo?.purchaseValue)}
                        color={true}
                        miniGraph={true}
                      />
                    </Grid>
                    <Grid item xl={6} xs={12}>
                      <GraphicBar
                        keys={saleValueInfo?.keys}
                        data={saleValueInfo?.profit}
                        title={Vocabulary.saleValue}
                        value={formatNumber(vehiclesInfo?.saleValue)}
                        color={true}
                        miniGraph={true}
                      />
                    </Grid>
                    {/* <Grid item xl={4} xs={12}>
                      <GraphicBar
                        keys={saleDurationInfo?.keys}
                        data={saleDurationInfo?.profit}
                        title={Vocabulary.statisticallySaleDuration}
                        value={formatNumber(vehiclesInfo?.saleDuration)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                      />
                    </Grid> */}
                    <Grid item xl={6} xs={12}>
                      <GraphicBar
                        keys={saleDurationAverageInfo?.keys}
                        data={saleDurationAverageInfo?.profit}
                        title={Vocabulary.saleDurationAverage}
                        value={formatNumber(vehiclesInfo?.saleDurationAverage)}
                        color={true}
                        noCurency={true}
                        miniGraph={true}
                        averageValue={true}
                      />
                    </Grid>
                    {/* <Grid item xl={4} xs={12}>
                      <GraphicBar
                        keys={interestRateInfo?.keys}
                        data={interestRateInfo?.profit}
                        title={Vocabulary.interestRate}
                        value={formatNumber(vehiclesInfo?.interestRate)}
                        color={true}
                        miniGraph={true}
                      />
                    </Grid> */}

                    {/* <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={netIncomeGraphs?.keys}
                  data={netIncomeGraphs.profit}
                  title={Vocabulary.netIncome}
                  value={formatNumber(profitInfo?.netIncome)}
                  color={false}
                />
              </Grid> */}
                  </>
                ) : null}
                <Grid item xl={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={() => {
                      !openGraphs && openSecondGraphs(!openGraphs);
                      scrollToSection();
                    }}
                  >
                    {Vocabulary.seeGraphs}
                  </Button>
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
          {costsGraphs.keys ? (
            <>
              <Grid item xl={12} xs={12}>
                <GraphicBar
                  keys={grossProfitGraphs?.keys}
                  data={grossProfitGraphs.profit}
                  title={Vocabulary.grossProfit}
                  value={formatNumber(profitInfo?.grossProfit)}
                  color={true}
                />
              </Grid>
              {/* <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={netIncomeGraphs?.keys}
                  data={netIncomeGraphs.profit}
                  title={Vocabulary.netIncome}
                  value={formatNumber(profitInfo?.netIncome)}
                  color={false}
                />
              </Grid> */}
            </>
          ) : null}

          <Grid item xl={12} md={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Profit</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                xl={12}
                md={12}
                xs={12}
              >
                {/* <Grid item xl={6} md={6} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.netIncomeAverage}</span>
                    {formatNumber(profitInfo?.netIncomeAverage)}Lei
                  </p>
                </Grid> */}
                <Grid item xl={12} md={12} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.grossProfitAverage}</span>
                    {formatNumber(profitInfo?.grossProfitAverage)}
                    Lei
                  </p>
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
          {costsGraphs.keys ? (
            <>
              <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={costsGraphs?.keys}
                  data={costsGraphs.vehicleCosts}
                  title={Vocabulary.vehiclesCosts}
                  value={formatNumber(costsInfo?.vehicleCosts)}
                  color={true}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                <GraphicBar
                  keys={costsGraphs?.keys}
                  data={costsGraphs.vehicleCostsAverage}
                  title={Vocabulary.vehicleCostsAverage}
                  value={formatNumber(costsInfo?.vehicleCostsAverage)}
                  color={false}
                  averageValue={true}
                />
              </Grid>
            </>
          ) : null}
          <Grid item xl={12} md={12} xs={12}>
            <fieldset className={style.statisticallyFieldset}>
              <legend>Costuri</legend>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                xl={12}
                md={12}
                xs={12}
              >
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.additionalCosts}</span>
                    {formatNumber(costsInfo?.additionalCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.administrativeCosts}</span>
                    {formatNumber(costsInfo?.administrativeCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.logisticsCosts}</span>
                    {formatNumber(costsInfo?.logisticsCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.marketingCosts}</span>
                    {formatNumber(costsInfo?.marketingCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.guaranteeCosts}</span>
                    {formatNumber(costsInfo?.guaranteeCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.detailingCosts}</span>
                    {formatNumber(costsInfo?.detailingCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.paintCosts}</span>
                    {formatNumber(costsInfo?.paintCosts)} Lei
                  </p>
                </Grid>
                <Grid item xl={2} md={2} xs={12}>
                  <p className={style.statisticallyText}>
                    <span>{Vocabulary.serviceCosts}</span>
                    {formatNumber(costsInfo?.serviceCosts)} Lei
                  </p>
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
