/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../Styles/genericModal.module.css";
import MUIDataTable from "mui-datatables";
import React, { useContext, useEffect } from "react";
import GenericModal from "./GenericModal";

import { ThemeProvider } from "@emotion/react";

import { getMuiTheme } from "../Utils/globalStyleTables";
import { Vocabulary } from "../Utils/Vocabulary";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postData } from "../Services/postData";
import { urlEnum } from "../Utils/urlEnum";
import { CacheContext } from "../Context/cacheContext";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { updateData } from "../Services/updateData";
import { multipleDelete } from "../Services/multipleDelete";
import { isMobile, mergeObjects } from "../Utils/utils";
import { tableLayoutOnMobile } from "../Utils/Constants";

type Props = {
  open: boolean;
  onClose: () => void;
  id: any;
  children: React.ReactNode;
  title: any | any;
  columns?: object[] | any;
  validationSchema?: any;
  data: any;
  modelForFields?: any;
  hiddenFieldsArray?: string[] | any;
  onEdit?: (dataIndex: number, rowIndex: number) => void;
  onSucces?: () => void;
};

function GenericAutocompleteModal(props: Props) {
  const {
    open,
    children,
    onClose,
    title,
    columns,
    id,
    validationSchema,
    data,
    modelForFields,
    hiddenFieldsArray,
    onSucces,
  } = props;
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const cacheContext: any = useContext(CacheContext);
  const cacheData = cacheContext.cache;
  const [localState, setLocalState] = React.useState(modelForFields);
  const [deletedOption, setDeletedOption]: any = React.useState([]);

  useEffect(() => {
    setLocalState(modelForFields);
  }, [modelForFields]);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (model: any) => {
    const newModel = mergeObjects(modelForFields, model);
    if (modelForFields.id) editTypes(id, newModel, modelForFields.id);
    else createTypes(id, model);
  };

  const onError = (model: any) => {
    //
  };

  /**
   *
   * @param data
   */
  const refreshCache = (data: any) => {
    const id: any = Object.keys(data.data);
    const tempLocalStorage: any = Object.assign({}, cacheData);
    id.forEach((element: any) => {
      if (cacheData[element]) tempLocalStorage[element] = data.data[element];
    });
    cacheContext.setCache(tempLocalStorage);
  };

  /**
   *
   * @param id
   * @param data
   * @returns
   */
  async function createTypes(id: any, data: any) {
    const newData = { ...modelForFields, ...data };
    const url = `/${urlEnum[id]}`;

    const res = (await postData(url, newData)) as any;
    if (!res || res?.error) {
      return;
    }
    onClose();
    refreshCache(res);
    onSucces && onSucces();
  }

  /**
   *
   * @param id
   * @param data
   */
  async function deteleType(id: any, data: any) {
    const allData = { data: { ids: data } };
    const url = `/${urlEnum[id]}`;
    const res = (await multipleDelete(url, allData)) as any;
    if (!res || res?.error) {
      return;
    }
    onClose();
    refreshCache(res);
    onSucces && onSucces();
  }

  /**
   *
   * @param id
   * @param data
   * @param idType
   * @returns
   */
  async function editTypes(id: any, data: any, idType: any) {
    const url = `/${urlEnum[id]}/${idType}`;
    const res = (await updateData(url, data)) as any;

    if (!res || res?.error) {
      return;
    }
    onClose();
    refreshCache(res);
    onSucces && onSucces();
  }

  /**
   *
   */
  const localOptions = {
    responsive: tableLayoutOnMobile,
    filter: false,
    download: false,
    sort: false,
    viewColumns: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    print: false,
    rowsPerPage: 10,
    page: 0,
    search: true,
    rowHover: true,
    tableBodyHeight: isMobile() ? "30vh" : "45vh",
    searchPlaceholder: Vocabulary.search,
    textLabels: {
      selectedRows: {
        text: Vocabulary.selectedRows,
        delete: Vocabulary.delete,
        deleteAria: Vocabulary.deleteSelectedLines,
      },
      toolbar: {
        search: Vocabulary.search,
      },
      body: {
        noMatch: Vocabulary.noData,
      },
    },
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => {
      const ids: any = [];
      selectedRows.data.forEach((element: any, index: any) => {
        ids.push(data[element.index].id);
      });
      return (
        <Button
          variant="outlined"
          style={{ marginRight: 10 }}
          onClick={() => handleDeleteOptions(props.id, ids)}
        >
          {Vocabulary.delete} {ids.length}{" "}
          {ids.length === 1 ? Vocabulary.option : Vocabulary.optionsLowerCase}{" "}
        </Button>
      );
    },
    customToolbar: () => {
      return (
        <Button
          variant="outlined"
          //onClick={() => handleDeleteOptions(props.id, ids)}

          disabled
        >
          {Vocabulary.delete}
        </Button>
      );
    },
  };

  const columnsWithEdit = columns.slice();
  columnsWithEdit.push({
    name: "",
    label: "",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, tableMeta: any) => (
        <div style={{ float: "right" }}>
          {tableMeta?.rowData[1] !== Vocabulary.buyBackLabel ? (
            <ModeEditIcon
              style={{ height: 17, cursor: "pointer", color: "#4449" }}
              onClick={() => props.onEdit && props.onEdit(value, tableMeta)}
            ></ModeEditIcon>
          ) : null}
        </div>
      ),
    },
  });

  const handleChangeTextField = (event: any) => {
    const newState = Object.assign({}, localState);
    newState[event.target.id] = event.target.value;
    setLocalState(newState);
  };

  const handleDeleteOptions = (id: any, data: any) => {
    setOpenDeleteModal(true);
    setDeletedOption(data);
  };

  const confirmDelete = () => {
    deteleType(id, deletedOption);
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            // maxWidth: maxWidth ? maxWidth : 850,
          },
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        <p className={styles.title}>
          {Vocabulary.options} {title}
        </p>
        <p className={styles.close}>
          <CloseIcon onClick={() => onClose()} className={styles.closeIcon} />
        </p>
      </DialogTitle>
      <ThemeProvider theme={getMuiTheme()}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          method="get"
          id="formAddNewOption"
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {Object.keys(modelForFields)?.map((element: any) => {
            return hiddenFieldsArray &&
              hiddenFieldsArray?.includes(element) ? null : (
              <Controller
                name={element}
                control={control}
                render={({
                  field: {
                    value = localState[element],
                    onChange,
                    ref,
                    ...field
                  },
                  fieldState: { error },
                }) => (
                  <TextField
                    onChange={(event: any) => {
                      onChange(event.target.value);
                      handleChangeTextField(event);
                    }}
                    value={value}
                    error={!!error}
                    name={element}
                    helperText={
                      modelForFields.id
                        ? Vocabulary.askBeforeSave
                        : error?.message
                    }
                    id={element}
                    label={Vocabulary[element]}
                    size="small"
                    variant="outlined"
                    style={{ width: "60%" }}
                  />
                )}
              />
            );
          })}

          <Button type="submit" variant="contained" form="formAddNewOption">
            {Vocabulary.save}
          </Button>
        </form>
        <div style={{ margin: 20 }}>
          <MUIDataTable
            title={""}
            data={data}
            columns={columnsWithEdit}
            options={localOptions}
          />
        </div>
        {/* // )} */}
      </ThemeProvider>
      <div style={{ marginTop: -20 }}>
        <DialogContent>{children}</DialogContent>
      </div>

      <GenericModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title=""
      >
        <div style={{ textAlign: "center" }}>
          <p style={{ fontSize: 20 }}>{Vocabulary.askForDeleteAutocomplete}</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => confirmDelete()}
              variant="contained"
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#4448",
                marginLeft: 30,
              }}
              onClick={() => setOpenDeleteModal(false)}
            >
              {Vocabulary.no}
            </Button>
          </div>
        </div>
      </GenericModal>
    </Dialog>
  );
}

export default GenericAutocompleteModal;
